import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router";
import { Breadcrumbs } from "@material-ui/core";

import {
  NavContainer,
  PathContainer,
  NavItem,
  NavCurrentItem,
  ProfileImageContainer,
} from "./BreadcrumbMenu.elements";
import { AppContext } from "../../services";
import { UserDefaultIcon } from "../../assets/svgs";
import { AxiosFunctions } from "../../services/api-integration";

const BreadcrumbMenu: React.FC = () => {
  const { setCurrentPath, triggerNavReload, setTriggerNavReload } = useContext(
    AppContext
  );

  const [avatarImage, setAvatarImage] = useState("");
  const [reloadNav, setReloadNav] = useState(false);

  const history = useHistory();
  const location = history.location;

  const handleUserAvatar = async () => {
    const apiInstance = new AxiosFunctions();

    const apiResponse = await apiInstance.getProfileInfo();

    if (apiResponse) {
      const { avatar } = apiResponse;
      setAvatarImage(avatar);
    }
  };

  useEffect(() => {
    if (location.pathname === "/")
      if (window.innerWidth < 700)
        setCurrentPath([{ name: "Início", path: "/" }]);
      else setCurrentPath([{ name: "Tela inicial", path: "/" }]);

    handleUserAvatar();
  }, [location, setCurrentPath, triggerNavReload, reloadNav]);

  const handleRedirect = (path: string, index: number) => {
    const aux = sessionStorage.getItem("BREADCRUMB_PATH");

    if (aux) {
      const pathArray = JSON.parse(aux);

      const newPathArray = pathArray.filter((path: any, pathIndex: number) => {
        return pathIndex <= index;
      });

      setCurrentPath(newPathArray);
      setTriggerNavReload();
      setReloadNav((previousState) => !previousState);

      history.push(path);
    }
  };

  const handleProfileRedirect = () => {
    const aux = sessionStorage.getItem("BREADCRUMB_PATH");

    if (aux) {
      const pathArray = JSON.parse(aux);

      const isAlreadyInProfilePage = pathArray.map(({ name }: any) => {
        if (name === "Perfil") return true;

        return false;
      });

      if (isAlreadyInProfilePage.includes(true)) return;

      setCurrentPath([...pathArray, { name: "Perfil", path: "/perfil" }]);

      history.push("/perfil");
    }
  };

  const handleItemsToShow = () => {
    const aux = sessionStorage.getItem("BREADCRUMB_PATH");
    let itemsToShow: any = [];

    if (aux) {
      const pathArray = JSON.parse(aux);

      itemsToShow = pathArray.map(({ name, path }: any, index: number) => {
        if (pathArray.length - 1 === index)
          return (
            <NavCurrentItem
              onClick={() => handleRedirect(path, index)}
              key={`${path}+${name}+${index}`}
            >
              {name}
            </NavCurrentItem>
          );

        return (
          <NavItem
            onClick={() => handleRedirect(path, index)}
            key={`${path}+${name}+${index}`}
          >
            {name}
          </NavItem>
        );
      });
    }

    return itemsToShow;
  };

  return (
    <NavContainer>
      <PathContainer>
        <Breadcrumbs
          separator="<"
          aria-label="Menu Breadcrumb"
          style={{ color: "white" }}
          maxItems={window.innerWidth < 700 ? 2 : 8}
        >
          {handleItemsToShow()}
        </Breadcrumbs>
      </PathContainer>

      <ProfileImageContainer onClick={handleProfileRedirect}>
        {avatarImage && (
          <img
            src={avatarImage}
            alt="Foto de perfil"
            style={{ height: "inherit" }}
            loading="lazy"
          />
        )}

        {!avatarImage && <UserDefaultIcon />}
      </ProfileImageContainer>
    </NavContainer>
  );
};

export default BreadcrumbMenu;
