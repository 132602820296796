import styled from "styled-components";

export const ClientViewSection = styled.section`
  padding: 50px 30px;
  width: 100%;
  position: relative;
`;

export const ClientViewTopInfo = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;

  @media only screen and (max-width: 1024px) {
    height: 175px;
  }

  @media only screen and (max-width: 600px) {
    height: fit-content;
    flex-direction: column-reverse;
  }
`;

export const ShortcutInfoContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;

  @media only screen and (max-width: 1024px) {
    height: 100px;
  }

  @media only screen and (max-width: 600px) {
    display: block;
    height: fit-content;
  }
`;

export const ClientPreview = styled.header`
  width: 100%;
  margin-bottom: 50px;
  padding: 0 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #0c2340;
  height: 50px;

  @media only screen and (max-width: 500px) {
    height: 150px;
    flex-direction: column;
    justify-content: space-around;
  }
`;

export const ClientInfo = styled.main`
  display: flex;
  height: 100%;
  width: 500px;
  max-width: 100%;
  justify-content: space-around;
  align-items: center;

  @media only screen and (max-width: 500px) {
    height: fit-content;
    flex-direction: column;
  }
`;

export const ProfileImageContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dddddd;
  margin-right: 10px;
  overflow: hidden;

  svg {
    height: 24px;
    width: 24px;
  }

  @media only screen and (max-width: 1280px) {
    width: 45px;
    height: 45px;
  }
`;

export const ClientName = styled.h1`
  color: #ed931f;
  font-size: 20px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ClientContractInfo = styled.p``;

export const AddDocument = styled.button`
  border: none;
  padding: 5px 15px;
  background-color: #b3c2f2;
  box-shadow: 0px 3px 6px #00000029;
  transition: all 200ms ease-in-out;
  font: normal normal normal 16px "Open Sans", sans-serif;
  cursor: pointer;

  &:hover {
    background-color: #91a8f2;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;
