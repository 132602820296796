import React, { useState, FormEvent } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { SectionForm, FormTitle, SignInButton } from "./LoginForm.elements";
import LoginInput from "../LoginInput";
import { handleEmailValidation, handlePasswordValidation } from "./handlers";
import { LoginFormSignature } from "./LoginForm.signature";

const LoginForm: React.FC<LoginFormSignature> = ({
  handleDataSubmission,
  areCredentialsWrong,
  isLoading,
}) => {
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const handleValidation = () => {
    const emailValidationResult = handleEmailValidation(
      emailValue,
      setIsEmailValid
    );
    const passwordValidationResult = handlePasswordValidation(
      passwordValue,
      setIsPasswordValid
    );

    if (emailValidationResult === false || passwordValidationResult === false)
      return false;
    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validationResult = handleValidation();

    if (!validationResult) return;

    const body = {
      email: emailValue,
      password: passwordValue,
    };

    await handleDataSubmission(body);
  };

  return (
    <SectionForm onSubmit={handleSubmit} noValidate>
      <FormTitle>Login</FormTitle>

      <LoginInput
        label="E-Mail"
        type="email"
        isValid={areCredentialsWrong ? false : isEmailValid}
        value={emailValue}
        onChange={setEmailValue}
      />
      <LoginInput
        label="Senha"
        type="password"
        isValid={areCredentialsWrong ? false : isPasswordValid}
        value={passwordValue}
        onChange={setPasswordValue}
      />

      {isLoading ? (
        <CircularProgress />
      ) : (
        <SignInButton type="submit">ENTRAR</SignInButton>
      )}
    </SectionForm>
  );
};

export default LoginForm;
