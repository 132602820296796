import styled from "styled-components";

export const UnderConstructionPage = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #031326;
  z-index: -2;

  #gears {
    z-index: 2;
    width: 355px;
    position: absolute;
    top: 315px;
    right: 100px;

    @media only screen and (max-width: 1024px) {
      width: 220px;
      top: calc(100% - 260px);
      right: 24px;
    }

    @media only screen and (max-width: 700px) {
      width: 220px;
      top: calc(100% - 260px);
      right: 24px;
    }

    @media only screen and (max-height: 320px) {
      width: 120px;
      top: calc(100% - 150px);
      right: 24px;
    }
  }

  #background {
    position: absolute;
  }
`;

export const MainContent = styled.main`
  width: 560px;
  display: flex;
  flex-direction: column;
  color: white;
  z-index: 2;
  position: absolute;
  top: 200px;
  left: 320px;

  @media only screen and (max-width: 900px) {
    top: 50px;
    left: 24px;
  }

  @media only screen and (max-width: 320px) {
    top: 20px;
  }
`;

export const PageHeader = styled.h1`
  font-family: "Amerika Sans", sans-serif;
  font-size: 50px;
  font-weight: normal;
  margin-bottom: 40px;

  @media only screen and (max-width: 1024px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 540px) {
    width: 220px;
    top: calc(100% - 260px);
    right: 24px;
  }
`;

export const PageSubtitle = styled.h2`
  font-size: 30px;
  font-weight: normal;

  @media only screen and (max-width: 900px) {
    max-width: 350px;
  }

  @media only screen and (max-width: 520px) {
    font-size: 18px;
    max-width: 250px;
  }
`;

export const PageDarkMask = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: black;
  opacity: 0.4;
  z-index: 1;

  @media only screen and (max-width: 900px) {
    opacity: 0.3;
  }
`;
