/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import {
  ClientViewSection,
  ClientViewTopInfo,
  ShortcutInfoContainer,
  ClientPreview,
  ClientInfo,
  ProfileImageContainer,
  ClientName,
  ClientContractInfo,
  AddDocument,
} from "./AssessorClientView.elements";
import {
  FolderCard,
  AssessorFileView,
  HomeLastArchivesList,
  HomeMonthFolders,
  AddDocumentModal,
  AssessorPreview,
} from "../../components";
import { UserDefaultIcon } from "../../assets/svgs";
import { AppContext } from "../../services/context";
import { AxiosFunctions } from "../../services/api-integration";
import { IWeek, IWeekDeliveries } from "../../protocols/weeks.protocol";
import {
  getContractTimeRemaining,
  getCurrentMonthNumber,
  DateHandler,
} from "../../utils";

interface IClientBody {
  advisoryServices: string[];
  avatar: string;
  createdAt: string;
  email: string;
  guid: string;
  name: string;
  companyName: string;
  roles: string[];
  updatedAt: string;
}

interface IContractInfo {
  advisoryId: string;
  contractedItems: string[];
  createdAt: string;
  duration: number;
  guid: string;
  startedAt: Date;
  updatedAt: string;
  weeks: Array<IWeek>;
}

interface IFolderInfo {
  timestamp: string;
  date: string;
  number: number;
}

const monthNames = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const AssessorClientView: React.FC = () => {
  const history = useHistory();
  const params = useParams();

  const { userRole, setCurrentPath } = useContext(AppContext);

  const sessionStorageItem = sessionStorage.getItem("USER_ROLE");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientInfo, setClientInfo] = useState<IClientBody>();
  const [isLoading, setIsLoading] = useState(true);
  const [isLastDeliveriesLoading, setIsLastDeliveriesLoading] = useState(true);
  const [clientAdvisorBasicInfo, setClientAdvisorBasicInfo] = useState({
    name: "",
    guid: "",
    avatar: "",
  });
  const [timeOfContractRemaining, setTimeOfContractRemaining] = useState("");
  const [contractedItems, setContractedItems] = useState<string[]>([]);
  const [currentMonthAndYear, setCurrentMonthAndYear] = useState("");
  const [currentWeek, setCurrentWeek] = useState(0);
  const [monthFoldersInfo, setMonthFoldersInfo] = useState<Array<IFolderInfo>>(
    []
  );
  const [lastDeliverables, setLastDeliverables] = useState<
    Array<IWeekDeliveries>
  >();
  const [currentAdvisoryId, setCurrentAdvisoryId] = useState("");
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [contractStartDate, setContractStartDate] = useState<Date>();
  const [loadPage, setLoadPage] = useState(false);

  const handleFolderCardMobile = () => {
    if (window.innerWidth < 700) {
      return true;
    }

    return false;
  };

  const getAdvisorBasicInfo = (users: Array<IClientBody>) => {
    const { name, guid, avatar } = users.filter(
      (user: IClientBody) => user.roles[0] === "ADVISOR"
    )[0];

    return {
      name,
      guid,
      avatar,
    };
  };

  const handleContractDates = (contractInfo: IContractInfo) => {
    const { id } = params as any;

    const monthsFoldersArray: Array<IFolderInfo> = [];

    const currentMonth = monthNames[new Date().getMonth()];
    const currentYear = new Date().getFullYear();
    setCurrentMonthAndYear(`${currentMonth} - ${currentYear}`);

    const contractDurationInMonths = Math.floor(contractInfo.weeks.length / 4);

    const contractStartDate = new Date(contractInfo.startedAt);
    const contractInitialYear = contractStartDate.getFullYear();
    const initialMonth = new Date(contractInfo.startedAt).getMonth();
    let yearSumAux = 0;
    let yearLoop = 0;

    for (
      let currentLoopMonth = initialMonth;
      currentLoopMonth <= initialMonth + contractDurationInMonths;
      currentLoopMonth++
    ) {
      yearSumAux += 1;
      yearLoop = yearSumAux % 12 === 0 ? (yearLoop += 1) : yearLoop;

      const folderMonthNumber = currentLoopMonth % 12;
      const folderYear = contractStartDate.getFullYear();

      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const loopMonth = monthNames.indexOf(monthNames[folderMonthNumber]);

      const currentFolder = {
        timestamp:
          contractStartDate.getFullYear() - contractInitialYear > 0
            ? "future"
            : loopMonth - currentMonth === 0
            ? "present"
            : loopMonth - currentMonth > 0
            ? "future"
            : "past",
        date: `${monthNames[folderMonthNumber]} - ${folderYear}`,
        number: currentLoopMonth,
      };

      monthsFoldersArray.push(currentFolder);
      contractStartDate.setMonth(loopMonth + 1);
    }

    const resumedData = {
      monthAndYear: `${currentMonth} - ${currentYear}`,
      monthsFoldersArray,
      startDate: contractInfo.startedAt,
    };

    sessionStorage.setItem(
      `CLIENT_CONTRACT_INFO_${id}`,
      JSON.stringify(resumedData)
    );

    setContractStartDate(contractInfo.startedAt);
    setMonthFoldersInfo(monthsFoldersArray);
  };

  const handleLastDeliverables = async (
    contractWeeks: IWeek[],
    contractInfo: IContractInfo,
    advisoryId: string
  ) => {
    const { id } = params as any;

    const dateHandler = new DateHandler();

    const contractStart = new Date(contractInfo.startedAt);

    const initialContractMonth = new Date(
      `${contractStart.getMonth() + 1}-01-${contractStart.getFullYear()}`
    );

    const initialWeek = dateHandler.getWeekNumber(initialContractMonth);

    const currentWeek = dateHandler.getWeekNumber(new Date());

    const currentWeekNumber = (currentWeek[1] - initialWeek[1]) % 4;

    const apiInstance = new AxiosFunctions();

    const allDeliverables = await apiInstance.get({
      pathname: "deliverables",
      params: {
        advisoryId,
      },
    });

    const thisWeekDeliverables = allDeliverables.filter((deliverable: any) => {
      return deliverable.week === currentWeek[1] - initialWeek[1];
    });

    setCurrentWeek(currentWeekNumber);
    setLastDeliverables(thisWeekDeliverables);
    setIsLastDeliveriesLoading(false);

    const resumedData = {
      currentWeekNumber,
      thisWeekDeliverables,
    };

    sessionStorage.setItem(
      `THIS_WEEK_DELIVERABLES_${id}`,
      JSON.stringify(resumedData)
    );
  };

  const handleSettingContractDuration = (
    duration: number,
    numberOfMonths: number
  ) => {
    const { id } = params as any;
    let contractDurationMessage = "";

    if (numberOfMonths === 1) {
      contractDurationMessage = `${
        numberOfMonths + 1
      }/${duration} mês de contrato`;
    } else {
      contractDurationMessage = `${
        numberOfMonths + 1
      }/${duration} meses de contrato`;
    }

    sessionStorage.setItem(`CONTRACT_DURATION_${id}`, contractDurationMessage);

    setTimeOfContractRemaining(contractDurationMessage);
  };

  const handleGetClientInfo = useCallback(
    async (userRole: string | null) => {
      const { id } = params as any;
      const apiInstance = new AxiosFunctions();
      let userInfo: any;

      if (userRole && userRole === "CLIENT") {
        userInfo = await apiInstance.getProfileInfo();
      } else {
        userInfo = await apiInstance.get({ pathname: `users/${id}` });
      }

      const allAdvisoriesServicesInfo = await apiInstance.get({
        pathname: "advisory-services",
        params: { includeUsers: true },
      });

      const clientAdvisoryService = allAdvisoriesServicesInfo.filter(
        (advisoryService: any) =>
          advisoryService.guid === userInfo.advisoryServices[0]
      )[0];

      if (clientAdvisoryService) {
        const contractInfo = await apiInstance.get({
          pathname: `contracts/${clientAdvisoryService.contractId}`,
          params: {
            advisoryId: clientAdvisoryService.guid,
          },
        });

        const advisorBasicInfo = getAdvisorBasicInfo(
          clientAdvisoryService.included.users
        );

        const contractTime = getContractTimeRemaining(contractInfo.weeks);

        const resumedData = {
          advisoryServiceId: clientAdvisoryService.guid,
          advisorInfo: advisorBasicInfo,
          itemsOfContract: contractInfo.contractedItems,
          userInfo,
        };

        sessionStorage.setItem(
          `CLIENT_BASIC_INFO_${id}`,
          JSON.stringify(resumedData)
        );

        handleLastDeliverables(
          contractInfo.weeks,
          contractInfo,
          clientAdvisoryService.guid
        );
        handleContractDates(contractInfo);
        handleSettingContractDuration(
          contractTime,
          getCurrentMonthNumber(contractInfo.weeks)
        );

        setCurrentAdvisoryId(clientAdvisoryService.guid);
        setClientAdvisorBasicInfo(advisorBasicInfo);
        setContractedItems(contractInfo.contractedItems);
        setClientInfo(userInfo);
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    const { id } = params as any;

    const userRole = sessionStorage.getItem("USER_ROLE");
    const clientBasicInfo = sessionStorage.getItem(`CLIENT_BASIC_INFO_${id}`);
    const basicContractInfo = sessionStorage.getItem(
      `CLIENT_CONTRACT_INFO_${id}`
    );
    const contractDurationInfo = sessionStorage.getItem(
      `CONTRACT_DURATION_${id}`
    );
    const weekDeliverables = sessionStorage.getItem(
      `THIS_WEEK_DELIVERABLES_${id}`
    );

    if (
      !clientBasicInfo ||
      !contractDurationInfo ||
      !weekDeliverables ||
      !basicContractInfo
    )
      handleGetClientInfo(userRole);
    else {
      const {
        advisoryServiceId,
        advisorInfo,
        itemsOfContract,
        userInfo,
      } = JSON.parse(clientBasicInfo);

      const { monthAndYear, monthsFoldersArray, startDate } = JSON.parse(
        basicContractInfo
      );

      const { actualWeekNumber, thisWeekDeliverables } = JSON.parse(
        weekDeliverables
      );

      setCurrentAdvisoryId(advisoryServiceId);
      setClientAdvisorBasicInfo(advisorInfo);
      setContractedItems(itemsOfContract);
      setClientInfo(userInfo);
      setCurrentMonthAndYear(monthAndYear);
      setMonthFoldersInfo(monthsFoldersArray);
      setContractStartDate(startDate);
      setCurrentWeek(actualWeekNumber);
      setLastDeliverables(thisWeekDeliverables);
      setTimeOfContractRemaining(contractDurationInfo);

      setIsLoading(false);
    }

    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    handleFolderCardMobile();
  }, [handleGetClientInfo, dimensions, loadPage]);

  const handleRedirectToAssessor = (name: string, guid: string) => {
    const aux = sessionStorage.getItem("BREADCRUMB_PATH");

    if (aux) {
      const pathArray = JSON.parse(aux);
      setCurrentPath([
        ...pathArray,
        {
          name: name,
          path: `/assessor/${name}/${guid}`,
        },
      ]);
      history.push(`/assessor/${name}/${guid}`);
    }
  };

  const handleRedirectToClient = (name: string, guid: string) => {
    const aux = sessionStorage.getItem("BREADCRUMB_PATH");

    if (aux) {
      const pathArray = JSON.parse(aux);
      setCurrentPath([
        ...pathArray,
        {
          name: "Perfil",
          path: `/cliente/perfil/${name}/${guid}`,
        },
      ]);

      history.push(`/cliente/perfil/${name}/${guid}`);
    }
  };

  const handleGettingMonthWeek = (date: string) => {
    let auxSum = 0;

    const monthFoldersArray = monthFoldersInfo.map((folder, index: Number) => {
      auxSum += 1;
      if (date === folder.date) return auxSum;

      return null;
    });

    const monthSum = monthFoldersArray.filter((value) => value !== null)[0];

    if (monthSum) return monthSum * 4;

    return -1;
  };

  return (
    <ClientViewSection>
      {!isLoading && (
        <>
          {userRole !== "CLIENT" && sessionStorageItem !== "CLIENT" && (
            <ClientPreview>
              <ClientInfo
                onClick={() => {
                  const isAdmin = sessionStorageItem === "ADMIN" ? true : false;

                  if (!isAdmin) return;

                  const { clientName, id } = params as any;

                  return handleRedirectToClient(clientName, id);
                }}
                style={{
                  cursor: sessionStorageItem === "ADMIN" ? "pointer" : "",
                }}
              >
                <ProfileImageContainer>
                  {clientInfo ? (
                    <img
                      src={clientInfo.avatar}
                      alt="Foto de perfil do cliente"
                      style={{ height: "inherit" }}
                    />
                  ) : (
                    <UserDefaultIcon />
                  )}
                </ProfileImageContainer>
                {window.innerWidth > 700 ? "|" : ""}
                <ClientName>
                  {clientInfo ? clientInfo.companyName : "Nome do cliente"}
                </ClientName>

                {window.innerWidth > 700 ? "|" : ""}
                <ClientContractInfo>
                  {timeOfContractRemaining}
                </ClientContractInfo>
              </ClientInfo>

              {userRole === "ADVISOR" || sessionStorageItem === "ADVISOR" ? (
                <AddDocument
                  onClick={() =>
                    setIsModalOpen((previousState) => !previousState)
                  }
                >
                  + Documento
                </AddDocument>
              ) : (
                <ClientName
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleRedirectToAssessor(
                      clientAdvisorBasicInfo.name,
                      clientAdvisorBasicInfo.guid
                    )
                  }
                >
                  {clientAdvisorBasicInfo.name}
                </ClientName>
              )}
            </ClientPreview>
          )}

          <ClientViewTopInfo>
            <ShortcutInfoContainer>
              <FolderCard
                timestamp="present"
                date={currentMonthAndYear}
                number="5"
                isMobile={handleFolderCardMobile()}
                advisoryId={currentAdvisoryId}
                week={handleGettingMonthWeek(currentMonthAndYear)}
              />
              {isLastDeliveriesLoading && !lastDeliverables ? (
                <div
                  style={{
                    width: "100%",
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <HomeLastArchivesList
                    week={currentWeek}
                    isAdvisor={sessionStorageItem === "ADVISOR"}
                  >
                    {lastDeliverables?.map((deliverable: IWeekDeliveries) => (
                      <AssessorFileView
                        key={`${deliverable.advisoryId}+${deliverable.guid}`}
                        name={deliverable.title}
                        category={deliverable.type}
                        content={deliverable.content}
                        id={deliverable.guid}
                      />
                    ))}
                  </HomeLastArchivesList>
                </>
              )}
            </ShortcutInfoContainer>

            {sessionStorageItem === "CLIENT" && (
              <AssessorPreview
                name={`Assessor: ${clientAdvisorBasicInfo.name}`}
                avatar={clientAdvisorBasicInfo.avatar}
              />
            )}
          </ClientViewTopInfo>

          <HomeMonthFolders>
            {monthFoldersInfo &&
              monthFoldersInfo.map((value, index: number) => (
                <FolderCard
                  timestamp={value.timestamp}
                  date={value.date}
                  number={(value.number as unknown) as string}
                  key={value.date}
                  advisoryId={currentAdvisoryId}
                  week={(index + 1) * 4}
                />
              ))}
          </HomeMonthFolders>

          {isModalOpen && (
            <AddDocumentModal
              clientName={clientInfo ? clientInfo.name : ""}
              isOpen={isModalOpen}
              handleModalClose={setIsModalOpen}
              contractedItems={contractedItems}
              monthsOfTheContract={monthFoldersInfo.map(
                (folder: any) => folder.date
              )}
              advisoryId={currentAdvisoryId}
              contractStartDate={contractStartDate}
              triggerPageLoading={setLoadPage}
              contractId={(params as any).id as string}
            />
          )}
        </>
      )}

      {isLoading && <CircularProgress />}
    </ClientViewSection>
  );
};

export default AssessorClientView;
