import { EmailValidatorAdapter } from "../../../../utils";

export function handleEmailValidation(
  email: string,
  callback: Function
): boolean {
  const emailValidator = new EmailValidatorAdapter();
  const isEmailValid = emailValidator.isValid(email);
  callback(isEmailValid);
  return isEmailValid;
}
