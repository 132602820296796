/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ChangeEvent,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

import {
  SectionContainer,
  ClientsListSection,
  AssessorListSection,
  ClientCard,
  ClientCardHeader,
  ClientCardSubtitle,
  DeactivatedClientCard,
  AdminClientActions,
  ClientsListContainer,
  AssessorCard,
  SearchBar,
  AddButton,
  HeaderTitle,
  HeaderGrouper,
  AssessorListContainer,
  AdminAssessorActions,
  CircularProgressContainer,
} from "./AdminHome.elements";
import { AddAssessorModal, AddClientModal } from "../../components";
import { AZFilter } from "../../assets/svgs";
import { AppContext } from "../../services/context";
import { AxiosFunctions } from "../../services/api-integration";

interface IUserBody {
  advisoryServices: string[];
  avatar: string;
  createdAt: string;
  email: string;
  guid: string;
  name: string;
  roles: string[];
  updatedAt: string;
  isActive: boolean;
}

const AdminHome: React.FC = () => {
  const history = useHistory();
  const { setCurrentPath } = useContext(AppContext);

  const [assessorsToShow, setAssessorsToShow] = useState<
    IUserBody[] | undefined
  >([]);
  const [isAddClientsModalOpen, setIsAddClientsModalOpen] = useState(false);
  const [isAddAssessorsModalOpen, setIsAddAssessorsModalOpen] = useState(false);
  const [allAssessors, setAllAssessors] = useState<IUserBody[] | undefined>();
  const [allClients, setAllClients] = useState<any | IUserBody[] | undefined>(
    []
  );
  const [clientsToShow, setClientsToShow] = useState(allClients);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadPage, setReloadPage] = useState(false);

  const clientsPerPage = useMemo(() => {
    return window.innerWidth < 600 ? 5 : 15;
  }, [window.innerWidth]);

  const handleGettingAllAssessorsAndClients = (allUsers: [IUserBody]) => {
    const allAssessorsArray = allUsers.filter(
      (user) => user.roles[0] === "ADVISOR"
    );
    const allClientsArray = allUsers.filter(
      (user) => user.roles[0] === "CLIENT"
    );

    const resumedData = {
      clientsArray: allClientsArray,
      assessorsArray: allAssessorsArray,
    };

    sessionStorage.setItem("ADMIN_HOME_INFO", JSON.stringify(resumedData));

    setAllClients(allClientsArray);
    setClientsToShow(allClientsArray);
    setAllAssessors(allAssessorsArray);
    setIsLoading(false);
  };

  const getUsersOnMount = async () => {
    const apiInstance = new AxiosFunctions();
    const users = await apiInstance.get({
      pathname: "users",
      onError: toast.error,
    });

    handleGettingAllAssessorsAndClients(users);
  };

  const handleSearchBarChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleClientsRender(clientsPerPage, e.target.value);
  };

  const handleClientsRender = (numOfClientsToShow: number, search: string) => {
    let clientsToShowArray: any = [];

    if (search && allClients) {
      allClients.map((client: IUserBody) => {
        if (client.name.toLowerCase().includes(search.toLowerCase()))
          return clientsToShowArray.push(client);
        return -1;
      });
    } else {
      clientsToShowArray = [...allClients];
    }

    const slicedClientsToShow = clientsToShowArray.slice(0, numOfClientsToShow);
    setClientsToShow(slicedClientsToShow);
  };

  useEffect(() => {
    const sessionStorageItem = sessionStorage.getItem("ADMIN_HOME_INFO");

    if (!sessionStorageItem) getUsersOnMount();
    else {
      const { clientsArray, assessorsArray } = JSON.parse(sessionStorageItem);

      setAllClients(clientsArray);
      setClientsToShow(clientsArray);
      setAllAssessors(assessorsArray);
      setIsLoading(false);
    }
  }, [clientsPerPage, reloadPage]);

  const handleClientsFilter = () => {
    if (clientsToShow) {
      const clientsOrderedAlphabetically = [
        ...clientsToShow,
      ].sort((clientA, clientB) => clientA.name.localeCompare(clientB.name));

      setClientsToShow(clientsOrderedAlphabetically);
    }
  };

  const handleAssessorsFilter = () => {
    if (assessorsToShow) {
      const assessorsOrderedAlphabetically = [
        ...assessorsToShow,
      ].sort((assessorA, assessorB) =>
        assessorA.name.localeCompare(assessorB.name)
      );
      setAssessorsToShow(assessorsOrderedAlphabetically);
    }
  };

  const handleRedirect = (typeofPath: string, name: string, id: string) => {
    const aux = sessionStorage.getItem("BREADCRUMB_PATH");

    if (aux) {
      const pathArray = JSON.parse(aux);
      setCurrentPath([
        ...pathArray,
        {
          name: name,
          path: `/${typeofPath}/${name}/${id}`,
        },
      ]);
      history.push(`/${typeofPath}/${name}/${id}`);
    }
  };

  return (
    <SectionContainer>
      <ClientsListSection>
        <AdminClientActions>
          <HeaderGrouper>
            <HeaderTitle>Clientes</HeaderTitle>
            <AddButton onClick={() => setIsAddClientsModalOpen(true)}>
              + Cliente
            </AddButton>
          </HeaderGrouper>

          <HeaderGrouper id="search-bar-group">
            <SearchBar
              placeholder="Search..."
              onChange={handleSearchBarChange}
            />
            <AZFilter
              style={{ cursor: "pointer", marginLeft: "20px" }}
              onClick={handleClientsFilter}
            />
          </HeaderGrouper>
        </AdminClientActions>

        <ClientsListContainer>
          {!isLoading &&
            allClients &&
            clientsToShow.map((client: IUserBody) => {
              return (client.advisoryServices &&
                client.advisoryServices.length < 1) ||
                !client.isActive ? (
                <DeactivatedClientCard
                  key={`${client.name}+${client.name}+${Math.random()}`}
                  onClick={() =>
                    handleRedirect("cliente", client.name, client.guid)
                  }
                >
                  <ClientCardHeader>{client.name}</ClientCardHeader>

                  <ClientCardSubtitle>Desativado</ClientCardSubtitle>
                </DeactivatedClientCard>
              ) : (
                <ClientCard
                  key={`${client.name}+${client.name}+${Math.random()}`}
                  onClick={() =>
                    handleRedirect("cliente", client.name, client.guid)
                  }
                >
                  <ClientCardHeader>{client.name}</ClientCardHeader>
                </ClientCard>
              );
            })}

          {isLoading && (
            <CircularProgressContainer>
              <CircularProgress />
            </CircularProgressContainer>
          )}
        </ClientsListContainer>
      </ClientsListSection>

      <AssessorListSection>
        <AdminAssessorActions>
          <HeaderGrouper>
            <HeaderTitle>Assessores</HeaderTitle>
            <AddButton onClick={() => setIsAddAssessorsModalOpen(true)}>
              + Assessor
            </AddButton>
          </HeaderGrouper>

          <AZFilter
            style={{ cursor: "pointer" }}
            onClick={handleAssessorsFilter}
          />
        </AdminAssessorActions>

        <AssessorListContainer>
          {allAssessors &&
            allAssessors.map(({ name, advisoryServices, guid }, index) => (
              <AssessorCard
                key={`${name}+${advisoryServices.length}+${index}`}
                onClick={() => handleRedirect("assessor", name, guid)}
              >
                <ClientCardSubtitle>
                  {name} - {advisoryServices.length}{" "}
                  {advisoryServices.length > 1 || advisoryServices.length === 0
                    ? "clientes"
                    : "cliente"}
                </ClientCardSubtitle>
              </AssessorCard>
            ))}

          {!allAssessors && (
            <CircularProgressContainer>
              <CircularProgress />
            </CircularProgressContainer>
          )}
        </AssessorListContainer>
      </AssessorListSection>

      {isAddClientsModalOpen && (
        <AddClientModal
          open={isAddClientsModalOpen}
          handleClose={setIsAddClientsModalOpen}
          allAssessors={allAssessors}
          triggerPageReload={setReloadPage}
        />
      )}

      {isAddAssessorsModalOpen && (
        <AddAssessorModal
          open={isAddAssessorsModalOpen}
          handleClose={setIsAddAssessorsModalOpen}
          triggerPageReload={setReloadPage}
        />
      )}
    </SectionContainer>
  );
};

export default AdminHome;
