import styled from "styled-components";

export const ProfileSection = styled.section`
  height: fit-content;
  min-height: 100%;
  width: 100vw;
  padding: 50px 30px;
  display: flex;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 700px) {
    padding: 30px 15px 0 30px;
    position: relative;
  }
`;

export const MainSection = styled.main`
  width: 50%;
  height: fit-content;

  @media only screen and (max-width: 700px) {
    width: 80%;
  }
`;

export const ProfileBasicInfo = styled.div`
  display: flex;
  width: 415px;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 215px;
  }
`;

export const GreetingMessages = styled.div`
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 700px) {
    width: 210px;
  }
`;

export const ClientName = styled.h2`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    margin-top: 15px;
    font-size: 1rem;
  }
`;

export const ContractTimeRemaining = styled.h3`
  font-weight: normal;

  @media only screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
`;

export const ProfileImage = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  padding: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dddddd;

  svg {
    height: 24px;
    width: 24px;
  }

  @media only screen and (max-width: 700px) {
    height: 80px;
    width: 80px;
    padding: 24px;
  }
`;

export const UserImage = styled.img``;
