import React, { Dispatch, SetStateAction, useState } from "react";

const setUserRole: Dispatch<SetStateAction<string>> = () => {};

const setCurrentPath = (path: Array<any>) => {
  sessionStorage.setItem("BREADCRUMB_PATH", JSON.stringify(path));
};

let triggerNavReload = false;

const setTriggerNavReload = () => {
  triggerNavReload = !triggerNavReload;
};

const AppContext = React.createContext({
  userRole: "",
  setUserRole,
  setCurrentPath,
  triggerNavReload,
  setTriggerNavReload,
});

const AppProvider: React.FC = ({ children }) => {
  const [userRole, setUserRole] = useState("");

  return (
    <AppContext.Provider
      value={{
        userRole,
        setUserRole,
        setCurrentPath,
        triggerNavReload,
        setTriggerNavReload,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
