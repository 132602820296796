import styled from "styled-components";

interface ISeeMoreButton {
  isDeactivated: boolean;
}

export const SectionContainer = styled.section`
  position: relative;
  width: 100%;
  padding: 50px 30px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 825px) {
    flex-direction: column;
  }
`;

export const ClientsListSection = styled.main`
  width: 70%;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  position: relative;

  @media only screen and (max-width: 825px) {
    width: 100%;
    padding: 0;
  }
`;

export const AssessorListSection = styled.aside`
  width: 30%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-shadow: -3px 0px 2px #dfdfdf;
  padding-left: 20px;
  position: relative;

  @media only screen and (max-width: 825px) {
    width: 100%;
    box-shadow: none;
    padding: 0;
  }
`;

export const ClientCard = styled.div`
  width: calc((100% - 75px) / 5);
  height: 105px;
  background: #fbfbfb;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 15px 15px 0;
  padding: 0 12px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const ClientCardHeader = styled.h1`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #031326;
`;

export const ClientCardSubtitle = styled.p`
  font-size: 14px;
  letter-spacing: 0px;
  color: #031326;

  @media only screen and (max-width: 1440px) {
    font-size: 12px;
  }
`;

export const DeactivatedClientCard = styled.div`
  width: calc((100% - 75px) / 5);
  height: 105px;
  background: #dedede;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 0.4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 15px 15px 0;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const AdminClientActions = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding-right: 15px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;

    #search-bar-group {
      margin-top: 15px;
    }
  }
`;

export const ClientsListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const AssessorCard = styled.div`
  width: 100%;
  height: 35px;
  background: #fbfbfb;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  cursor: pointer;
`;

export const SearchBar = styled.input`
  width: 350px;
  border: 1px solid #0c2340;
  color: #0c2340;
  padding: 12px 0 12px 24px;

  &::placeholder {
    color: #0c2340;
  }

  @media only screen and (max-width: 600px) {
    width: 181px;
  }
`;

export const AddButton = styled.button`
  border: none;
  padding: 5px 15px;
  background-color: #b3c2f2;
  box-shadow: 0px 3px 6px #00000029;
  transition: all 200ms ease-in-out;
  font: normal normal normal 16px "Open Sans", sans-serif;
  cursor: pointer;

  &:hover {
    background-color: #91a8f2;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const HeaderTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #031326;
  margin-right: 20px;
`;

export const HeaderGrouper = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const AssessorListContainer = styled.div`
  width: 100%;
`;

export const AdminAssessorActions = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

export const SeeMoreButton = styled.button<ISeeMoreButton>`
  border: none;
  padding: 10px;
  width: 250px;
  background-color: ${(props) => (props.isDeactivated ? "#dedede" : "#b3c2f2")};
  opacity: ${(props) => (props.isDeactivated ? "0.4" : "")};
  align-self: center;
  box-shadow: 0px 3px 6px #00000029;
  transition: all 200ms ease-in-out;
  margin: 10px 0;
  font: normal normal normal 14px "Open Sans", sans-serif;
  cursor: pointer;

  &:focus {
    background-color: ${(props) =>
      props.isDeactivated ? "#dedede" : "#91a8f2"};
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const CircularProgressContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
