import styled from "styled-components";

import { IWeekFolder } from "./WeekFolder.signature";

export const CardContainer = styled.div`
  width: 160px;
  height: 160px;
  position: relative;
  margin-right: 15px;
  cursor: pointer;

  @media only screen and (max-width: 1600px) {
    width: 120px;
  }

  @media only screen and (max-width: 1440px) {
    width: 105px;
  }

  @media only screen and (max-width: 1280px) {
    height: 130px;
  }

  @media only screen and (max-width: 1024px) {
    width: 96px !important;
    height: 104px;
  }

  @media only screen and (max-width: 700px) {
    margin-bottom: 25px;
  }
`;

export const WeekName = styled.header<IWeekFolder>`
  width: 100%;
  height: 30px;
  border: 1px solid #ed931f;
  display: ${(props) => {
    if (props.shouldHideTopBorder) return "none";

    return "flex";
  }};

  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    height: 20px;
  }
`;

export const CardIconContainer = styled.div<IWeekFolder>`
  width: 100%;
  height: ${(props) => {
    if (props.shouldHideTopBorder) return "120px";

    return "90px";
  }};

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;

  @media only screen and (max-width: 1024px) {
    width: 96px !important;
    height: ${(props) => {
      if (props.shouldHideTopBorder) return "82px";

      return "52px";
    }};

    svg {
      width: 20.24px;
      height: 24px;
    }
  }
`;

export const CardTextContainer = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const CardText = styled.p`
  font-size: 20px;

  @media only screen and (max-width: 1600px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 1280px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;
