import styled from "styled-components";

export const CreateNewPasswordPage = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #031326;
  z-index: -2;
  overflow: hidden !important;
  position: relative;

  #background {
    position: absolute;
  }
`;

export const IllustrationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg:first-child {
    width: 325px;
  }

  svg {
    width: 160px;
  }

  @media only screen and (max-width: 500px) {
    svg {
      max-width: 230px;
    }
  }
`;

export const MainInfoContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fbfbfb;
  min-height: 800px;
  min-width: 600px;
  padding-top: 60px;
  z-index: 2;

  @media only screen and (max-height: 780px) {
    min-height: 600px;
    padding-top: 0;
  }

  @media only screen and (max-width: 600px) {
    min-width: 340px;
    min-height: 90%;
  }

  @media only screen and (max-width: 320px) {
    min-width: 300px;
  }
`;

export const FormGroup = styled.form`
  width: 325px;
  height: 425px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px;
  position: relative;

  @media only screen and (max-width: 600px) {
    width: 260px;
    margin-top: 0;
  }

  @media only screen and (max-width: 400px) {
    justify-content: space-evenly;
  }
`;

export const SendButton = styled.button`
  background-color: #b3c2f2;
  width: 120px;
  height: 40px;
  border: 0px solid #031326;
  box-shadow: 0px 3px #00000029;
  transition: background-color 0.1s ease;

  :hover {
    background-color: #91a8f2;
  }
`;

export const FormTitle = styled.h1`
  color: #031326;
  font-size: 25px;

  @media only screen and (max-width: 400px) {
    font-size: 18px;
  }
`;

export const FormText = styled.p`
  color: #031326;
  font-size: 20px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
`;

export const TextContainer = styled.div`
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media only screen and (max-width: 400px) {
    height: 65px;
  }
`;

export const InstructionsContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const InstructionTextContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const InstructionText = styled.p`
  font-size: 12px;
  color: #031326;
  margin-left: 5px;
`;
