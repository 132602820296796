import React, { useState } from "react";
import { Modal, CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { IModal } from "./GenericModalInterface";
import {
  ModalContainer,
  CloseIconContainer,
  ModalTitle,
  ModalSubtitle,
  CancelButton,
  AddButton,
} from "./GenericModalStyles.style";
import { CloseIcon } from "../../assets/svgs";
import { AxiosFunctions } from "../../services/api-integration";

interface IDeactivateUser extends IModal {
  userId: string;
  userName: string;
}

const DeactivateUserModal: React.FC<IDeactivateUser> = ({
  handleClose,
  open,
  userId,
  userName,
}) => {
  const history = useHistory();

  const [isDeletingUser, setIsDeletingUser] = useState(false);

  const handleUserDeletion = async () => {
    setIsDeletingUser(true);
    const apiInstance = new AxiosFunctions();

    await apiInstance.patch({
      pathname: `users/${userId}/isActive`,
      onSuccess: () => {
        toast.success(
          "Usuário desativado com sucesso! Redirecionando à página principal"
        );

        sessionStorage.removeItem("ADMIN_HOME_INFO");

        handleClose(false);
        setTimeout(() => {
          history.push("/");
        }, 2000);
        setIsDeletingUser(false);
        return;
      },
      onError: () => {
        toast.error("Houve um problema inesperado, por favor tente novamente");
        setIsDeletingUser(false);

        handleClose(true);
        return;
      },
    });
  };

  return (
    <Modal open={open}>
      <ModalContainer
        style={{ height: window.innerWidth < 825 ? "fit-content" : "280px" }}
      >
        <CloseIconContainer onClick={() => handleClose(false)}>
          <CloseIcon />
        </CloseIconContainer>

        <ModalTitle>Desativar usuário</ModalTitle>

        <ModalSubtitle>Deseja mesmo desativar {userName}?</ModalSubtitle>

        {isDeletingUser ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress style={{ alignSelf: "flex-end" }} />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CancelButton onClick={() => handleClose(false)}>
              Cancelar
            </CancelButton>
            <AddButton onClick={handleUserDeletion}>Desativar</AddButton>
          </div>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default DeactivateUserModal;
