/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CircularProgress, Menu, MenuItem } from "@material-ui/core";
import { useParams } from "react-router-dom";

import {
  ProfileSection,
  MainSection,
  ProfileBasicInfo,
  GreetingMessages,
  ClientName,
  ProfileImage,
  UserImage,
} from "./ClientProfileAdminView.elements";
import {
  ProfileAccountInfo,
  ProfileContractInfo,
  DeactivateUserModal,
} from "../../components";
import { AxiosFunctions } from "../../services/api-integration";
import { UserDefaultIcon, EditIcon } from "../../assets/svgs";

const ClientProfileAdminView: React.FC = () => {
  const params = useParams();
  const { id } = params as any;

  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({
    name: "",
    email: "",
    avatar: "",
    phone: "",
  });
  const [advisoryId, setAdvisoryId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getProfileInfo = async () => {
    const apiIntegration = new AxiosFunctions();

    const profileInfo = await apiIntegration.get({
      pathname: `users/${id}`,
    });

    if (profileInfo) {
      const { name, email, avatar, phone, advisoryServices } = profileInfo;
      const newUser = { name, email, avatar, phone };
      setUser(newUser);
      setAdvisoryId(advisoryServices[0]);

      const resumedData = {
        name,
        email,
        avatar,
        phone,
        advisoryServiceId: advisoryServices[0],
      };

      sessionStorage.setItem(
        `CLIENT_INFO_ID_${id}`,
        JSON.stringify(resumedData)
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const clientInfo = sessionStorage.getItem(`CLIENT_INFO_ID_${id}`);

    if (!clientInfo) getProfileInfo();
    else {
      const data = JSON.parse(clientInfo);

      const newUserObject = {
        name: data.name,
        email: data.email,
        avatar: data.avatar,
        phone: data.phone,
      };

      setUser(newUserObject);
      setAdvisoryId(data.advisoryServiceId);
      setIsLoading(false);
    }
  }, []);

  return (
    <ProfileSection>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <MainSection>
          <ProfileBasicInfo>
            <ProfileImage
              style={{
                padding: user.avatar && "0",
                overflow: user.avatar && "hidden",
              }}
            >
              {user.avatar ? (
                <UserImage
                  src={user.avatar}
                  alt="Imagem do perfil"
                  style={{ height: "100%" }}
                />
              ) : (
                <UserDefaultIcon />
              )}
            </ProfileImage>

            <GreetingMessages>
              <ClientName>
                {user.name}
                <button
                  style={{ border: "none", backgroundColor: "transparent" }}
                  onClick={handleClick}
                >
                  <EditIcon style={{ height: "18px" }} />
                </button>
              </ClientName>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setIsModalOpen(true);
                    handleClose();
                  }}
                >
                  Desativar
                </MenuItem>
              </Menu>
            </GreetingMessages>
          </ProfileBasicInfo>

          <ProfileAccountInfo
            email={user.email}
            name={user.name}
            phone={user.phone ? user.phone : "(11) 9.9000-0000"}
          />

          <ProfileContractInfo
            isPasswordChanging={false}
            advisoryId={advisoryId}
          />
        </MainSection>
      )}

      {isModalOpen && (
        <DeactivateUserModal
          open={isModalOpen}
          handleClose={setIsModalOpen}
          userId={id}
          userName={user.name}
        />
      )}
    </ProfileSection>
  );
};

export default ClientProfileAdminView;
