import React, { memo, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  CardContainer,
  CardIconContainer,
  CardTextContainer,
  CardText,
  SectionMobileHeader,
} from "./FolderCard.elements";
import { FolderBlack, FolderBlue, FolderWhite } from "../../assets/svgs";
import { IFolderCard } from "./FolderCard.signature";
import folderNameAdapter from "../../utils/folderNameParser";
import { AppContext } from "../../services";

const FolderCard: React.FC<IFolderCard> = ({
  date,
  number,
  timestamp,
  isMobile,
  week,
  advisoryId,
}) => {
  const history = useHistory();
  const { setCurrentPath } = useContext(AppContext);

  const handleFolderIcon = () => {
    if (timestamp === "present") return <FolderBlack />;
    if (timestamp === "past") return <FolderBlue />;
    return <FolderWhite />;
  };

  const handleRedirect = () => {
    const parsedDate = folderNameAdapter.parse(date);
    const aux = sessionStorage.getItem("BREADCRUMB_PATH");

    if (aux) {
      const pathArray = JSON.parse(aux);
      setCurrentPath([
        ...pathArray,
        {
          name: date,
          path: `/visualizacao-semanal/${parsedDate}/${week}/${advisoryId}`,
        },
      ]);
      history.push(`/visualizacao-semanal/${parsedDate}/${week}/${advisoryId}`);
    }
  };

  return (
    <CardContainer isMobile={isMobile} onClick={handleRedirect}>
      {isMobile && <SectionMobileHeader>Mês atual</SectionMobileHeader>}
      <CardIconContainer isMobile={isMobile}>
        {handleFolderIcon()}
      </CardIconContainer>

      <CardTextContainer>
        <CardText>{date}</CardText>
      </CardTextContainer>
    </CardContainer>
  );
};

export default memo(FolderCard);
