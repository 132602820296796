import styled from "styled-components";

interface ISendButton {
  isDeactivated: boolean;
}

export const EmailSentPage = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #031326;
  z-index: -2;
  overflow: hidden !important;
  position: relative;

  #background {
    position: absolute;
  }
`;

export const IllustrationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg:first-child {
    width: 325px;
  }

  svg {
    width: 160px;
  }

  @media only screen and (max-width: 500px) {
    svg {
      max-width: 230px;
    }
  }
`;

export const MainInfoContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fbfbfb;
  min-height: 800px;
  min-width: 600px;
  padding-top: 100px;
  z-index: 2;

  @media only screen and (max-height: 780px) {
    min-height: 600px;
    padding-top: 50px;
  }

  @media only screen and (max-width: 600px) {
    min-width: 340px;
    min-height: 90%;
  }

  @media only screen and (max-width: 320px) {
    min-width: 300px;
  }
`;

export const FormGroup = styled.form`
  width: 425px;
  height: 325px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;

  @media only screen and (max-width: 600px) {
    width: 260px;
    justify-content: space-around;
  }
`;

export const SendButton = styled.button<ISendButton>`
  background-color: ${(props) => (props.isDeactivated ? "#dedede" : "#b3c2f2")};
  opacity: ${(props) => (props.isDeactivated ? "0.4" : "")};
  width: 120px;
  height: 40px;
  border: 0px solid #031326;
  box-shadow: 0px 3px #00000029;
  transition: background-color 0.1s ease;
  cursor: ${(props) => (props.isDeactivated ? "not-allowed" : "pointer")};

  :hover {
    background-color: ${(props) =>
      props.isDeactivated ? "#dedede" : "#91a8f2"};
  }
`;

export const FormTitle = styled.h1`
  color: #031326;
  font-size: 25px;
`;

export const FormText = styled.p`
  color: #031326;
  font-size: 20px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

export const TextContainer = styled.div`
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const CurrentSecondsMessage = styled.p`
  color: #031326;
  font-size: 16px;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const SendButtonContainer = styled.div`
  height: 85px;
  display: inherit;
  flex-direction: inherit;
  align-items: inherit;
  justify-content: inherit;
`;
