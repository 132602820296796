import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Routes from "./routes";
import GlobalStyles from "./assets/styles/global";
import { AppProvider } from "./services";
import "./assets/styles/index.css";

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <GlobalStyles />
        <ToastContainer />
        <Routes />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
