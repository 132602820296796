import styled from "styled-components";

interface IUserRole {
  userRole: string | null;
}

export const ProfileSection = styled.section`
  height: fit-content;
  min-height: 100%;
  width: 100vw;
  padding: 50px 30px;
  display: flex;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 700px) {
    padding: 30px 15px 0 30px;
    position: relative;
  }
`;

export const MainSection = styled.main`
  width: 50%;
  height: fit-content;

  @media only screen and (max-width: 700px) {
    width: 80%;
  }
`;

export const ProfileBasicInfo = styled.div`
  display: flex;
  width: 415px;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 215px;
  }
`;

export const GreetingMessages = styled.div`
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 700px) {
    width: 210px;
  }
`;

export const Greeting = styled.h2`
  color: #ed942b;

  @media only screen and (max-width: 700px) {
    margin-top: 15px;
    font-size: 1rem;
  }
`;

export const ContractTimeRemaining = styled.h3`
  font-weight: normal;

  @media only screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
`;

export const ProfileImage = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  padding: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dddddd;

  svg {
    height: 24px;
    width: 24px;
  }

  @media only screen and (max-width: 700px) {
    height: 80px;
    width: 80px;
    padding: 24px;
  }
`;

export const IconsAside = styled.aside<IUserRole>`
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-height: 1024px) and (max-width: 1024px) {
    margin-bottom: 40px;
    width: 20%;
    height: ${(props) => props.userRole === "CLIENT" && "740px"};

    svg {
      width: 18px;
    }
  }

  @media only screen and (max-width: 700px) {
    margin-bottom: 40px;
    width: 20%;
    height: ${(props) => (props.userRole === "CLIENT" ? "860px" : "510px")};

    svg {
      width: 18px;
    }
  }
`;

export const EditButton = styled.button`
  background-color: transparent;
  border: none;
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 24px;
  }
`;

export const SignOutButton = styled.button`
  background-color: transparent;
  border: none;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media only screen and (max-width: 700px) {
    font-size: 1rem;
  }

  @media only screen and (max-height: 1024px) and (max-width: 1024px) {
    justify-content: center;
    font-size: 1rem;

    svg {
      margin-left: 5px;
    }
  }
`;

export const SaveButton = styled.button`
  background-color: #b3c2f2;
  width: 120px;
  height: 40px;
  border: 0px solid #031326;
  box-shadow: 0px 3px #00000029;
  transition: background-color 0.1s ease;
  bottom: 20px;
  right: 60px;

  :hover {
    background-color: #9eabd5;
  }

  @media only screen and (max-width: 600px) {
    width: 100px;
    margin-right: 24px;
    font-size: 12px;
  }
`;

export const UserImage = styled.img``;
