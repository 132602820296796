import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import {
  ClientCardsContainer,
  ClientCard,
  ClientImage,
  ClientInfo,
  ClientName,
  ClientContractInfo,
} from "./ClientsList.elements";
import { IClientsList } from "./ClientsList.signature";
import { AppContext } from "../../services/context";

const ClientsList: React.FC<IClientsList> = ({ clientsToShow, isLoading }) => {
  const history = useHistory();
  const { setCurrentPath } = useContext(AppContext);

  useEffect(() => {}, [clientsToShow]);

  const handleRedirect = (client: {
    advisoryServices: string[];
    avatar: string;
    cnpj: string;
    companyName: string;
    createdAt: string;
    email: string;
    guid: string;
    name: string;
    phone: string;
    roles: string[];
    updatedAt: string;
  }) => {
    const aux = sessionStorage.getItem("BREADCRUMB_PATH");

    if (aux) {
      const pathArray = JSON.parse(aux);

      setCurrentPath([
        ...pathArray,
        {
          name: client.companyName,
          path: `/cliente/${client.companyName}/${client.guid}`,
        },
      ]);
      history.push(`/cliente/${client.companyName}/${client.guid}`);
    }
  };

  return (
    <ClientCardsContainer>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        clientsToShow.map((client, index) => (
          <ClientCard
            onClick={() => handleRedirect(client)}
            key={`${client.name}+${index}`}
          >
            <ClientImage
              src={
                client.avatar
                  ? client.avatar
                  : "https://chapelaria-digital-dev-user-avatars.s3-sa-east-1.amazonaws.com/default-user-icon.png"
              }
            />

            <ClientInfo>
              <ClientName>{client.name}</ClientName>
              <ClientContractInfo>{client.cnpj}</ClientContractInfo>
            </ClientInfo>
          </ClientCard>
        ))
      )}
    </ClientCardsContainer>
  );
};

export default ClientsList;
