import { ITokenHandler } from "./tokenHandler.signature";

export class TokenHandler implements ITokenHandler {
  private readonly tokenName: string;

  constructor(tokenName: string) {
    this.tokenName = tokenName;
  }

  setToken(token: string): void {
    const { tokenName } = this;
    sessionStorage.setItem(tokenName, token);
  }

  getToken(): string | null {
    const { tokenName } = this;
    const tokenReceived = sessionStorage.getItem(tokenName);
    return tokenReceived;
  }

  removeToken(): void {
    const { tokenName } = this;
    sessionStorage.removeItem(tokenName);
  }
}
