import styled from "styled-components";

export const SectionContainer = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media only screen and (max-width: 700px) {
    justify-content: flex-start;
  }
`;

export const SectionHeader = styled.h2`
  @media only screen and (max-width: 700px) {
    font-size: 1.2rem;
    margin: 25px 0 15px 0;
  }
`;

export const InputsContainer = styled.div`
  max-width: 600px;
  height: 150px;
  display: grid;
  grid-column-gap: 25px;
  grid-template-areas:
    "name email aux"
    "phone oldPassword newPassword";

  @media only screen and (max-width: 700px) {
    display: flex;
    height: 300px;
    flex-direction: column;
    justify-content: space-between;
  }
`;
