import styled from "styled-components";

export const CardContainer = styled.div`
  width: 160px;
  height: 160px;
  position: relative;
  margin-right: 15px;
  cursor: pointer;

  @media only screen and (max-width: 1024px) {
    height: 145px;
  }

  @media only screen and (max-width: 700px) {
    margin-bottom: 25px;
  }
`;

export const CardMainContent = styled.main`
  width: 100%;
  height: 120px;
  padding: 0 0 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 3px 6px #00000029;

  @media only screen and (max-width: 1024px) {
    height: 105px;
    font-size: 14px;
  }
`;

export const CardTextContainer = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const CardText = styled.p`
  font-size: 16px;

  @media only screen and (max-width: 1280px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 12px;
  }
`;

export const DownloadTrigger = styled.a`
  background-color: transparent;
  border: none;
`;
