import React from "react";

import {
  UnderConstructionPage,
  MainContent,
  PageHeader,
  PageSubtitle,
  PageDarkMask,
} from "./UnderConstruction.elements";
import { LoginBackground, Gears } from "../../assets/svgs";

const UnderConstruction: React.FC = () => {
  return (
    <UnderConstructionPage>
      <MainContent>
        <PageHeader>Página em construção</PageHeader>
        <PageSubtitle>
          Ainda estamos construindo essa página, em breve ficará pronta.
        </PageSubtitle>
      </MainContent>

      <Gears id="gears" />

      <PageDarkMask />
      <LoginBackground id="background" />
    </UnderConstructionPage>
  );
};

export default UnderConstruction;
