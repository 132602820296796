import { IWeek } from "../../protocols/weeks.protocol";

export const getCurrentMonthNumber = (weeks: Array<IWeek>) => {
  const currentWeek = weeks.filter(
    (week: IWeek) => week.isActualWeek === true
  )[0];

  const currentWeekNumber = (currentWeek.label.split(
    " "
  )[1] as unknown) as number;

  const currentWeekInMonths = Math.floor(currentWeekNumber / 4);

  return currentWeekInMonths;
};
