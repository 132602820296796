import styled from "styled-components";

export const NavContainer = styled.nav`
  width: 100%;
  height: 100px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #0c2340;
`;

export const PathContainer = styled.main`
  width: auto;
  height: 100%;
  max-width: 650px;
  display: flex;
  align-items: center;
  color: white;

  @media only screen and (max-width: 700px) {
    max-width: 182px;
  }
`;

export const NavItem = styled.h2`
  text-decoration: none;
  font: normal normal normal 20px "Open Sans", sans-serif;
  cursor: pointer;

  @media only screen and (max-width: 700px) {
    font-size: 16px;
  }
`;

export const NavCurrentItem = styled.h1`
  text-decoration: none;
  font: normal normal bold 20px "Open Sans", sans-serif;
  cursor: pointer;

  @media only screen and (max-width: 700px) {
    font-size: 16px;
  }
`;

export const ProfileImageContainer = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dddddd;
  margin-right: 10px;
  overflow: hidden;
  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;
  }

  @media only screen and (max-width: 1280px) {
    width: 45px;
    height: 45px;
  }
`;
