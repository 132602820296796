import axios from "axios";

import TokenHandler from "../utils/tokenHandler";
import {
  IAxiosFunctions,
  IAxiosFunctionsProps,
  IAxiosFunctionsPropsWithBody,
  IAuthenticationBody,
  ILoginSessionResponse,
  IProfileInfo,
} from "./api-integrations.signature";

export class AxiosFunctions implements IAxiosFunctions {
  private readonly mainUrl: string | undefined;
  constructor() {
    this.mainUrl = process.env.REACT_APP_API_URL;
  }

  async get(props: IAxiosFunctionsProps) {
    try {
      const tokenHandler = new TokenHandler("auth_token");
      const token = tokenHandler.getToken();
      const apiResponse = await axios.get(
        `${this.mainUrl}/${props.pathname}`,
        props.params
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
                "X-Ignore-Cache": true,
              },
              params: props.params,
            }
          : {
              headers: {
                Authorization: `Bearer ${token}`,
                "X-Ignore-Cache": true,
              },
            }
      );

      if (props.onSuccess) return props.onSuccess(apiResponse.data);

      return apiResponse.data;
    } catch (err) {
      console.log(err);

      if (props.onError) {
        props.onError(err);
        return null;
      }
      return null;
    }
  }

  async patch(props: IAxiosFunctionsProps) {
    try {
      const tokenHandler = new TokenHandler("auth_token");
      const token = tokenHandler.getToken();

      const apiResponse = await axios.patch(
        `${this.mainUrl}/${props.pathname}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (props.onSuccess) return props.onSuccess(apiResponse.data);

      return apiResponse.data;
    } catch (err) {
      console.log(err);

      if (props.onError) {
        props.onError(err);
        return null;
      }
      return null;
    }
  }

  async patchWithBody(props: IAxiosFunctionsPropsWithBody) {
    try {
      const tokenHandler = new TokenHandler("auth_token");
      const token = tokenHandler.getToken();

      const apiResponse = await axios.patch(
        `${this.mainUrl}/${props.pathname}`,
        props.body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (props.onSuccess) return props.onSuccess(apiResponse.data);

      return apiResponse.data;
    } catch (err) {
      console.log(err);

      if (props.onError) {
        props.onError(err);
        return null;
      }
      return null;
    }
  }

  async post(props: IAxiosFunctionsPropsWithBody) {
    try {
      const tokenHandler = new TokenHandler("auth_token");
      const token = tokenHandler.getToken();
      const apiResponse = await axios.post(
        `${this.mainUrl}/${props.pathname}`,
        props.body,
        props.params
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: props.params,
            }
          : {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
      );

      if (props.onSuccess) return props.onSuccess(apiResponse.data);

      return apiResponse.data;
    } catch (err) {
      console.log(err);

      if (props.onError) {
        props.onError(err.response);
        return null;
      }
      return null;
    }
  }
  async put(props: IAxiosFunctionsPropsWithBody) {
    try {
      const tokenHandler = new TokenHandler("auth_token");
      const token = tokenHandler.getToken();

      const apiResponse = await axios.put(
        `${this.mainUrl}/${props.pathname}`,
        props.body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (props.onSuccess) return props.onSuccess(apiResponse.data);

      return apiResponse.data;
    } catch (err) {
      console.log(err);

      if (props.onError) {
        props.onError(err);
        return null;
      }
      return null;
    }
  }

  async delete(props: IAxiosFunctionsProps) {}

  /**
   * A helper method that integrates with the API and returns the user's profile
   */
  async getProfileInfo(): Promise<IProfileInfo | null> {
    try {
      const tokenHandler = new TokenHandler("auth_token");
      const token = tokenHandler.getToken();
      const apiResponse = await axios.get(`${this.mainUrl}/users/@me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  /**
   * A helper method that integrates with the API and returns the token if the user is correct
   * @param body An object with the email and the password of the user
   */
  async handleAuthentication(
    body: IAuthenticationBody
  ): Promise<ILoginSessionResponse | null> {
    try {
      const apiResponse = await axios.post(
        `${this.mainUrl}/session/login`,
        body
      );

      return apiResponse.data;
    } catch (err) {
      return null;
    }
  }
}
