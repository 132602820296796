import React, { useState } from "react";

import { IListDocumentsPerWeek } from "./ListDocumentsPerWeek.signature";
import {
  SectionContainer,
  SectionTopInfo,
  SectionHeader,
  SectionsDivisor,
  MainInfo,
} from "./ListDocumentsPerWeek.elements";
import { Chevron } from "../../assets/svgs";

const ListDocumentsPerWeek: React.FC<IListDocumentsPerWeek> = ({
  chevronId,
  infoId,
  weekNumber,
  children,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleMenuOpening = () => {
    const chevronIcon = document.getElementById(chevronId);
    const mainInfo = document.getElementById(infoId);

    if (chevronIcon && mainInfo) {
      if (isMenuOpen) {
        setIsMenuOpen(false);
        chevronIcon.style.transform = "rotate(180deg)";
        mainInfo.style.display = "none";
        return;
      }

      setIsMenuOpen(true);
      mainInfo.style.display = "flex";
      chevronIcon.style.transform = "rotate(270deg)";
      return;
    }
  };

  return (
    <SectionContainer>
      <SectionTopInfo>
        <Chevron id={chevronId} onClick={handleMenuOpening} />
        <SectionHeader>Semana {weekNumber}</SectionHeader>
        <SectionsDivisor />
      </SectionTopInfo>

      <MainInfo id={infoId}>{children}</MainInfo>
    </SectionContainer>
  );
};

export default ListDocumentsPerWeek;
