import React from "react";
import { useParams } from "react-router-dom";

import { ILastArchivesList } from "./HomeLastArchive.signature";
import {
  ListSection,
  ListHeader,
  List,
  NoArchivesMessage,
} from "./HomeLastArchivesList.elements";
import { DateHandler } from "../../utils";

const HomeLastArchivesList: React.FC<ILastArchivesList> = ({
  isAdvisor,
  week = null,
  children = null,
}) => {
  const params = useParams();
  const { id } = params as any;

  const dateHandler = new DateHandler();

  let currentWeek: any;

  const weekDeliverables = sessionStorage.getItem(
    `THIS_WEEK_DELIVERABLES_${id}`
  );

  if (weekDeliverables) {
    const { currentWeekNumber } = JSON.parse(weekDeliverables);
    currentWeek = week !== null ? week + 1 : currentWeekNumber + 1;
  } else {
    currentWeek = week !== null ? week + 1 : "";
  }

  return (
    <ListSection isAdvisor={isAdvisor}>
      <ListHeader>
        Arquivos da semana {currentWeek} de {dateHandler.getMonth(new Date())}
      </ListHeader>

      {children && Object.keys(children).length > 0 ? (
        <List>{children}</List>
      ) : (
        <NoArchivesMessage>
          A semana ainda não possui nenhum arquivo
        </NoArchivesMessage>
      )}
    </ListSection>
  );
};

export default HomeLastArchivesList;
