import { IWeek } from "../../protocols/weeks.protocol";

export const getContractTimeRemaining = (weeks: Array<IWeek>) => {
  const currentWeek = weeks.filter(
    (week: IWeek) => week.isActualWeek === true
  )[0];

  const totalTimeInMonths = weeks.length / 4;

  const currentWeekNumber = (currentWeek.label.split(
    " "
  )[1] as unknown) as number;

  const currentWeekInMonths = Math.floor(currentWeekNumber / 4);

  const timeRemaining = totalTimeInMonths - currentWeekInMonths;

  return timeRemaining;
};
