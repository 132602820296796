import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  LoginPage,
  MainInfoContainer,
  LogoContainer,
  ForgotPasswordAnchor,
} from "./Login.elements";
import { LoginForm } from "../../components";
import {
  LoginBackground,
  ChapelariaDigital,
  Metapolitica,
} from "../../assets/svgs";
import { AppContext, AxiosFunctions } from "../../services";
import { IAuthenticationBody } from "../../services/api-integrations.signature";
import TokenHandler from "../../utils/tokenHandler";

const Login: React.FC = () => {
  const history = useHistory();
  const params = useParams();

  const { next } = params as any;

  const [areCredentialsWrong, setAreCredentialsWrong] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setUserRole } = useContext(AppContext);

  const handleFormSubmit = async (body: IAuthenticationBody) => {
    const axiosFunctions = new AxiosFunctions();

    setIsLoading(true);
    const apiResponse = await axiosFunctions.handleAuthentication(body);

    if (!apiResponse) {
      setAreCredentialsWrong(true);
      setIsLoading(false);
      return;
    }

    if (!apiResponse.user) {
      setAreCredentialsWrong(true);
      setIsLoading(false);
      return;
    }

    const { token, user } = apiResponse;
    const tokenHandler = new TokenHandler("auth_token");
    tokenHandler.setToken(token);

    setUserRole(user.roles[0]);
    sessionStorage.setItem("USER_ROLE", user.roles[0]);

    setIsLoading(false);

    if (next) {
      const regExpression = /_/g;
      const nextPageToBeLoaded = next.replace(regExpression, "/");
      return history.push(`/${nextPageToBeLoaded}`);
    }

    return history.push("/");
  };

  const handlePasswordRetrieve = () => {
    history.push("/esqueci-a-senha");
  };

  return (
    <LoginPage>
      <MainInfoContainer>
        {/* LOGO */}
        <LogoContainer>
          <ChapelariaDigital />
          <Metapolitica />
        </LogoContainer>

        <LoginForm
          handleDataSubmission={handleFormSubmit}
          areCredentialsWrong={areCredentialsWrong}
          isLoading={isLoading}
        />

        {/* PASSWORD RETRIEVE */}
        <ForgotPasswordAnchor onClick={handlePasswordRetrieve}>
          Esqueceu a senha?
        </ForgotPasswordAnchor>
      </MainInfoContainer>

      <LoginBackground id="background" />
    </LoginPage>
  );
};

export default Login;
