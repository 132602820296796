import React, { useState, useEffect, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";

import {
  ProfileSection,
  MainSection,
  ProfileBasicInfo,
  GreetingMessages,
  Greeting,
  ProfileImage,
  IconsAside,
  EditButton,
  SignOutButton,
  UserImage,
  SaveButton,
} from "./Profile.elements";
import { ProfileAccountInfo, ProfileContractInfo } from "../../components";
import { SignOut, UserDefaultIcon, EditIcon } from "../../assets/svgs";
import TokenHandler from "../../utils/tokenHandler";
import { AxiosFunctions } from "../../services/api-integration";
import { handlePasswordValidation } from "./handler/handlePasswordValidation";

const Profile: React.FC = () => {
  const history = useHistory();

  const sessionStorageItem = sessionStorage.getItem("USER_ROLE");

  const [isPasswordChanging, setIsPasswordChanging] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({
    name: "",
    email: "",
    avatar: "",
    phone: "",
    guid: "",
  });
  const [advisoryId, setAdvisoryId] = useState("");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isSubmittingUpdate, setIsSubmittingUpdate] = useState(false);
  const [triggerPageLoading, setTriggerPageLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [contractDuration, setContractDuration] = useState("");
  const [filePreview, setFilePreview] = useState("");

  const handleLogOut = () => {
    const tokenHandler = new TokenHandler("auth_token");

    sessionStorage.clear();
    tokenHandler.removeToken();
    history.push("/login");
  };

  const handleFileUpload = () => {
    const fileInput = document.getElementById("file-input");
    fileInput?.click();
  };

  const handlePasswordParamsValidation = () => {
    if (newPassword.length < 8) {
      toast.error("A nova senha deve ter ao menos 8 caracteres!");
      return false;
    }

    const isPasswordValid = handlePasswordValidation(newPassword, () => {});

    if (!isPasswordValid) {
      toast.error(
        "A nova senha deve conter letras, números e caracteres especiais!"
      );
      return false;
    }

    return true;
  };

  const handleSubmitUpdate = async () => {
    const apiIntegration = new AxiosFunctions();
    setIsSubmittingUpdate(true);

    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      await apiIntegration.patchWithBody({
        pathname: `users/${user.guid}/avatar`,
        body: formData,
        onSuccess: () =>
          toast.success("Foto de perfil atualizada com sucesso!"),
      });

      sessionStorage.removeItem("PROFILE_INFO");
      setTriggerPageLoading((previousState) => !previousState);
    }

    if (newPassword && oldPassword) {
      const arePasswordsParamsValid = handlePasswordParamsValidation();

      if (arePasswordsParamsValid) {
        const body = {
          oldPassword,
          newPassword,
        };

        await apiIntegration.patchWithBody({
          pathname: `users/${user.guid}/password`,
          body,
          onSuccess: () => {
            toast.success("Senha atualizada com sucesso!");
            setFilePreview("");
            setSelectedFile(null);
          },
          onError: () => {
            toast.error(
              "Ocorreu um erro inesperado, por favor recarregue sua página e tente novamente"
            );
            setFilePreview("");
            setSelectedFile(null);
          },
        });

        sessionStorage.removeItem("PROFILE_INFO");
        setTriggerPageLoading((previousState) => !previousState);
      }
    }

    if (name && phone) {
      const body = {
        name,
        phone,
      };

      await apiIntegration.put({
        pathname: `users/${user.guid}`,
        body,
        onSuccess: () =>
          toast.success("Nome e telefone atualizados com sucesso!"),
      });

      sessionStorage.removeItem("PROFILE_INFO");
      setTriggerPageLoading((previousState) => !previousState);
    } else if (name && !phone) {
      const body = {
        name,
      };

      await apiIntegration.put({
        pathname: `users/${user.guid}`,
        body,
        onSuccess: () => toast.success("Nome atualizado com sucesso!"),
      });

      sessionStorage.removeItem("PROFILE_INFO");
      setTriggerPageLoading((previousState) => !previousState);
    } else if (!name && phone) {
      const body = {
        phone,
      };

      await apiIntegration.put({
        pathname: `users/${user.guid}`,
        body,
        onSuccess: () => toast.success("Telefone atualizado com sucesso!"),
      });

      sessionStorage.removeItem("PROFILE_INFO");
      setTriggerPageLoading((previousState) => !previousState);
    }

    setTimeout(() => {
      setIsSubmittingUpdate(false);
    }, 1000);
  };

  const getProfileInfo = async () => {
    const apiIntegration = new AxiosFunctions();
    const profileInfo = await apiIntegration.getProfileInfo();

    if (profileInfo) {
      const {
        name,
        email,
        avatar,
        phone,
        guid,
        advisoryServices,
      } = profileInfo;
      const newUser = { name, email, avatar, phone, guid };
      setUser(newUser);
      setAdvisoryId(advisoryServices[0]);

      const resumedData = {
        name,
        email,
        avatar,
        phone,
        guid,
        advisoryServiceId: advisoryServices[0],
      };

      sessionStorage.setItem("PROFILE_INFO", JSON.stringify(resumedData));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const sessionStorageItem = sessionStorage.getItem("PROFILE_INFO");
    const contractTimeRemaining = sessionStorage.getItem(
      "CONTRACT_DURATION_undefined"
    );

    if (contractTimeRemaining) setContractDuration(contractTimeRemaining);

    if (!sessionStorageItem) getProfileInfo();
    else {
      const {
        name,
        email,
        avatar,
        phone,
        guid,
        advisoryServiceId,
      } = JSON.parse(sessionStorageItem);
      setUser({ name, email, avatar, phone, guid });
      setAdvisoryId(advisoryServiceId);
      setIsLoading(false);
    }
  }, [triggerPageLoading]);

  return (
    <ProfileSection id="section-container">
      {isLoading && <CircularProgress />}

      {!isLoading && user && (
        <>
          <MainSection>
            <ProfileBasicInfo>
              <ProfileImage
                style={{
                  padding: user.avatar && "0",
                  overflow: user.avatar && "hidden",
                }}
              >
                {isPasswordChanging ? (
                  <>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id="file-input"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files) {
                          setFilePreview(
                            URL.createObjectURL(e.target.files[0])
                          );
                          setSelectedFile(e.target.files[0]);
                        }
                      }}
                    />
                    <button
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "0px 15px",
                        border: "none",
                        textAlign: "center",
                        backgroundColor: "transparent",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={handleFileUpload}
                      type="button"
                    >
                      {filePreview ? (
                        <img
                          src={filePreview}
                          alt="Nova imagem de perfil"
                          style={{ height: "100%" }}
                        />
                      ) : (
                        "Atualizar foto de perfil"
                      )}
                    </button>
                  </>
                ) : user.avatar ? (
                  <UserImage
                    src={user.avatar}
                    alt="Imagem do perfil"
                    style={{ height: "100%" }}
                  />
                ) : (
                  <UserDefaultIcon />
                )}
              </ProfileImage>

              <GreetingMessages>
                <Greeting>{`Olá, ${user.name}`}</Greeting>
                <p>{contractDuration}</p>
              </GreetingMessages>
            </ProfileBasicInfo>

            {sessionStorageItem === "CLIENT" && (
              <ProfileContractInfo
                isPasswordChanging={isPasswordChanging}
                advisoryId={advisoryId}
              />
            )}

            <ProfileAccountInfo
              email={user.email}
              name={isPasswordChanging ? name : user.name}
              phone={isPasswordChanging ? phone : user.phone}
              isInputDeactivated={!isPasswordChanging}
              setName={setName}
              setPhone={setPhone}
              setOldPassword={setOldPassword}
              setNewPassword={setNewPassword}
              oldPassword={isPasswordChanging ? oldPassword : "password@123"}
              newPassword={isPasswordChanging ? newPassword : "password@123"}
            />
          </MainSection>

          <IconsAside userRole={sessionStorageItem}>
            {isSubmittingUpdate ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <CircularProgress style={{ height: "100%", width: "22px" }} />
              </div>
            ) : isPasswordChanging ? (
              <SaveButton
                onClick={() => {
                  setIsPasswordChanging((previousState) => !previousState);
                  handleSubmitUpdate();
                }}
              >
                Salvar alterações
              </SaveButton>
            ) : (
              <EditButton
                onClick={() => {
                  setIsPasswordChanging((previousState) => !previousState);
                }}
              >
                <EditIcon />
              </EditButton>
            )}

            <SignOutButton onClick={handleLogOut}>
              Sair
              <SignOut />
            </SignOutButton>
          </IconsAside>
        </>
      )}
    </ProfileSection>
  );
};

export default Profile;
