import styled from "styled-components";

import { ILastArchivesList } from "./HomeLastArchive.signature";

export const ListSection = styled.section<ILastArchivesList>`
  max-width: 1000px;
  height: 205px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;

  @media only screen and (max-width: 1280px) {
    height: 130px;
    margin-left: 10px;
  }

  @media only screen and (max-width: 1024px) {
    max-width: ${(props) => (props.isAdvisor ? "700px" : "400px")};
    height: 175px;
    justify-content: start;
    overflow-x: scroll;
    overflow-y: visible;
    position: relative;
  }

  @media only screen and (max-width: 800px) {
    max-width: ${(props) => (props.isAdvisor ? "700px" : "230px")};
  }

  @media only screen and (max-width: 700px) {
    max-width: 100%;
    margin-left: 0;
    height: auto;
    overflow-x: unset;
  }
`;

export const ListHeader = styled.h2`
  font: normal normal bold 25px "Open Sans", sans-serif;

  @media only screen and (max-width: 1280px) {
    font-size: 20px;
    margin-bottom: 14px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 18px;
    margin-bottom: 5px;
  }

  @media only screen and (max-width: 700px) {
    margin-bottom: 25px;
  }
`;

export const NoArchivesMessage = styled.p`
  font: normal normal bold 16px "Open Sans", sans-serif;
  height: calc(100% - 34px);
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1280px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 1024px) {
    height: 75px;
  }

  @media only screen and (max-width: 600px) {
    align-items: flex-start;
  }
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  position: relative;

  @media only screen and (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;
