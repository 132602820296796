import React, { ChangeEvent, FormEvent, useState } from "react";
import { TextField, CircularProgress, withStyles } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  CreateNewPasswordPage,
  MainInfoContainer,
  TextContainer,
  FormTitle,
  FormGroup,
  FormText,
  IllustrationContainer,
  SendButton,
  InstructionText,
  InstructionTextContainer,
  InstructionsContainer,
} from "./CreateNewPassword.elements";
import {
  CreateNewPasswordIllustration,
  LoginBackground,
  CheckMarkCreateNewPasswordRule,
} from "../../assets/svgs";
import { handlePasswordValidation } from "./handler/handlePasswordValidation";
import { AxiosFunctions } from "../../services/api-integration";

const ErrorField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
      width: "100% !important",
    },
    "& .MuiFormControl": {
      width: "100% !important",
    },
  },
})(TextField);

const CreateNewPassword: React.FC = () => {
  const params = useParams();
  const history = useHistory();

  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordParamsValidation = () => {
    if (newPassword !== passwordConfirmation) {
      setIsPasswordValid(false);
      toast.error("As senhas devem ser iguais!");
      return false;
    }

    if (newPassword.length < 8) {
      setIsPasswordValid(false);
      toast.error("A nova senha deve ter ao menos 8 caracteres!");
      return false;
    }

    const isPasswordValid = handlePasswordValidation(
      newPassword,
      setIsPasswordValid
    );

    if (!isPasswordValid) {
      setIsPasswordValid(false);
      toast.error(
        "A nova senha deve conter letras, números e caracteres especiais!"
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const arePasswordParamsValid = handlePasswordParamsValidation();

    if (!arePasswordParamsValid) return;

    setIsLoading(true);

    const { token } = params as any;

    const apiInstance = new AxiosFunctions();

    const body = { newPassword };

    await apiInstance.post({
      pathname: `session/password-reset/${token}/finish`,
      body,
      onSuccess: () => {
        setIsLoading(false);
        toast.success(
          "Senha atualizada com sucesso! Redirecionando você à página de login"
        );

        setTimeout(() => history.push("/login"), 3000);
      },
      onError: () => {
        setIsLoading(false);
        toast.error(
          "Ocorreu um erro inesperado, por favor recarregue sua página e tente novamente"
        );
      },
    });
  };

  return (
    <>
      <CreateNewPasswordPage>
        <MainInfoContainer>
          <IllustrationContainer>
            <CreateNewPasswordIllustration />
          </IllustrationContainer>

          <FormGroup onSubmit={handleSubmit}>
            <TextContainer>
              <FormTitle>Criar nova senha</FormTitle>
              <FormText>Crie uma nova senha para sua conta</FormText>
            </TextContainer>
            {isPasswordValid ? (
              <>
                <TextField
                  placeholder="Senha"
                  type="password"
                  value={newPassword}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setNewPassword(e.target.value)
                  }
                />
                <TextField
                  placeholder="Confirmar Senha"
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPasswordConfirmation(e.target.value)
                  }
                />
              </>
            ) : (
              <>
                <ErrorField
                  placeholder="Senha"
                  type="password"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setNewPassword(e.target.value)
                  }
                  error={true}
                  value={newPassword}
                />
                <ErrorField
                  placeholder="Confirmar Senha"
                  type="password"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPasswordConfirmation(e.target.value)
                  }
                  error={true}
                  value={passwordConfirmation}
                />
              </>
            )}

            <InstructionsContainer>
              <InstructionTextContainer>
                <CheckMarkCreateNewPasswordRule />{" "}
                <InstructionText>
                  Deve ter ao menos 8 caracteres
                </InstructionText>
              </InstructionTextContainer>

              <InstructionTextContainer>
                <CheckMarkCreateNewPasswordRule />{" "}
                <InstructionText>
                  Deve conter ao menos uma letra
                </InstructionText>
              </InstructionTextContainer>

              <InstructionTextContainer>
                <CheckMarkCreateNewPasswordRule />{" "}
                <InstructionText>
                  Deve conter ao menos um número
                </InstructionText>
              </InstructionTextContainer>

              <InstructionTextContainer>
                <CheckMarkCreateNewPasswordRule />{" "}
                <InstructionText>
                  Deve conter ao menos um caractere especial
                </InstructionText>
              </InstructionTextContainer>

              <InstructionTextContainer>
                <CheckMarkCreateNewPasswordRule />{" "}
                <InstructionText>
                  As duas senhas devem ser iguais
                </InstructionText>
              </InstructionTextContainer>
            </InstructionsContainer>

            {isLoading ? (
              <CircularProgress />
            ) : (
              <SendButton type="submit">Criar</SendButton>
            )}
          </FormGroup>
        </MainInfoContainer>

        <LoginBackground id="background" />
      </CreateNewPasswordPage>
    </>
  );
};

export default CreateNewPassword;
