import React, { useEffect, useState } from "react";
import {
  Modal,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import { toast } from "react-toastify";

import { IModal } from "./GenericModalInterface";
import {
  ModalContainer,
  CloseIconContainer,
  ModalTitle,
  ModalSubtitle,
  SameLineInputsContainer,
  AddButton,
} from "./GenericModalStyles.style";
import { CheckMarkCreateNewPasswordRule, CloseIcon } from "../../assets/svgs";
import { AxiosFunctions } from "../../services/api-integration";
import {
  InstructionsContainer,
  InstructionText,
  InstructionTextContainer,
} from "../../views/CreateNewPassword/CreateNewPassword.elements";
import { PasswordValidatorAdapter } from "../../utils";

interface IClientRegisterModal extends IModal {
  allAssessors:
    | {
        advisoryServices: string[];
        avatar: string;
        createdAt: string;
        email: string;
        guid: string;
        name: string;
        roles: string[];
        updatedAt: string;
      }[]
    | undefined;
  triggerPageReload: Function;
}

const contractItems = [
  "Agenda Semanal",
  "Boletim de Eleições",
  "Boletim Setorial",
  "Proposições Acompanhadas",
  "Reuniões",
];

const AddClientModal: React.FC<IClientRegisterModal> = ({
  handleClose,
  open,
  allAssessors,
  triggerPageReload,
}) => {
  // Controlled inputs
  const [allAssessorsNames, setAllAssessorsNames] = useState([""]);
  const [selectedAssessor, setSelectedAssessor] = useState("");
  const [selectedContractItems, setSelectedContractItems] = useState<string[]>(
    []
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [contractDurationInMonths, setContractDurationInMonths] = useState(0);

  // Inputs validation
  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isAssessorSelectedValid, setIsAssessorSelectedValid] = useState(true);
  const [isRazaoSocialValid, setIsRazaoSocialValid] = useState(true);
  const [isCnpjValid, setIsCnpjValid] = useState(true);
  const [isContractDurationValid, setIsContractDurationValid] = useState(true);
  const [
    areSelectedContractItemsValid,
    setAreSelectedContractItemsValid,
  ] = useState(true);
  const [passwordErrorMessage, setPasswordErrorMassage] = useState("");

  // Client creation
  const [isCreatingClient, setIsCreatingClient] = useState(false);

  const handleInputsValidation = () => {
    const passwordValidator = new PasswordValidatorAdapter();

    if (name.length < 3) {
      setIsNameValid(false);
      setIsCreatingClient(false);
      return false;
    }

    if (!email.includes("@") || !email.includes(".")) {
      setIsEmailValid(false);
      setIsCreatingClient(false);
      return false;
    }

    if (phone.length < 8) {
      setIsPhoneValid(false);
      setIsCreatingClient(false);
      return false;
    }

    if (password.length < 8) {
      setIsPasswordValid(false);
      setIsCreatingClient(false);
      setPasswordErrorMassage("A senha deve conter pelo menos 8 caracteres");
      return false;
    }

    const isPasswordValid = passwordValidator.isValid(password);

    if (!isPasswordValid) {
      setIsPasswordValid(false);
      setIsCreatingClient(false);
      setPasswordErrorMassage(
        "A nova senha deve conter letras, números e caracteres especiais"
      );
      return false;
    }

    if (razaoSocial.length < 3) {
      setIsRazaoSocialValid(false);
      setIsCreatingClient(false);
      return false;
    }

    if (selectedAssessor.length < 1) {
      setIsAssessorSelectedValid(false);
      setIsCreatingClient(false);
      return false;
    }

    if (cnpj.length < 14) {
      setIsCnpjValid(false);
      setIsCreatingClient(false);
      return false;
    }

    if (contractDurationInMonths < 1) {
      setIsContractDurationValid(false);
      setIsCreatingClient(false);
      return false;
    }

    if (selectedContractItems.length < 1) {
      setAreSelectedContractItemsValid(false);
      setIsCreatingClient(false);
      return false;
    }

    return true;
  };

  const clientCreationCleanup = () => {
    setIsNameValid(true);
    setIsEmailValid(true);
    setIsPasswordValid(true);
    setIsPhoneValid(true);
    setIsAssessorSelectedValid(true);
    setIsRazaoSocialValid(true);
    setIsCnpjValid(true);
    setIsContractDurationValid(true);
  };

  const getAssessorByName = (assessorName: string) => {
    return allAssessors?.filter(
      (assessor) => assessor.name === assessorName
    )[0];
  };

  const handleClientCreation = async () => {
    setIsCreatingClient(true);
    clientCreationCleanup();

    const apiInstance = new AxiosFunctions();

    const areInputsValid = handleInputsValidation();
    if (!areInputsValid) return;

    const clientCreationBody = {
      name,
      email,
      password,
      phone,
      companyName: razaoSocial,
      cnpj,
    };

    const clientCreationResponse = await apiInstance.post({
      pathname: "users/client",
      body: clientCreationBody,
    });

    if (clientCreationResponse) {
      const assessorOfChoice = getAssessorByName(selectedAssessor);
      const advisoryCreationBody = {
        users: [assessorOfChoice?.guid, clientCreationResponse.guid],
      };

      const advisoryCreationResponse = await apiInstance.post({
        pathname: "advisory-services",
        body: advisoryCreationBody,
      });

      if (advisoryCreationResponse) {
        const contractCreationBody = {
          duration: contractDurationInMonths * 4,
          startedAt: new Date(),
          contractedItems: selectedContractItems,
        };

        await apiInstance.post({
          pathname: "contracts",
          body: contractCreationBody,
          params: { advisoryId: advisoryCreationResponse.guid },
          onError: () => {
            toast.error(
              "Houve um problema inesperado, por favor tente novamente"
            );
            setIsCreatingClient(false);

            handleClose(false);
          },
          onSuccess: () => {
            setName("");
            setEmail("");
            setPassword("");
            setPhone("");
            setRazaoSocial("");
            setCnpj("");
            setContractDurationInMonths(0);
            setSelectedAssessor("");
            setSelectedContractItems([]);
            setIsCreatingClient(false);

            sessionStorage.removeItem("ADMIN_HOME_INFO");

            triggerPageReload(true);
            handleClose(false);
            toast.success("Cliente criado com sucesso!");
          },
        });
      }
    } else {
      toast.error("Houve um erro, e-mail já cadastrado");
      setIsCreatingClient(false);

      handleClose(false);
    }

    setIsCreatingClient(false);
  };

  useEffect(() => {
    if (allAssessors) {
      const assessorsNames = allAssessors.map(({ name }) => name);
      setAllAssessorsNames(assessorsNames);
    }
  }, [allAssessors]);

  const handleAssessorChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedAssessor(event.target.value as string);
  };

  const handleContractItemChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedContractItems(event.target.value as string[]);
  };

  return (
    <Modal open={open}>
      <ModalContainer shouldAdaptToWindow={true}>
        <CloseIconContainer onClick={() => handleClose(false)}>
          <CloseIcon />
        </CloseIconContainer>

        <ModalTitle>Adicionar cliente</ModalTitle>

        <ModalSubtitle>Informações da conta</ModalSubtitle>
        <SameLineInputsContainer>
          <TextField
            placeholder="Nome"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
            error={!isNameValid}
            value={name}
            helperText={
              !isNameValid && "O nome deve conter mais de 2 caracteres"
            }
          />
          <TextField
            placeholder="E-mail"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            value={email}
            error={!isEmailValid}
            helperText={!isEmailValid && "O e-mail deve ser um e-mail válido"}
          />
        </SameLineInputsContainer>

        <SameLineInputsContainer>
          <TextField
            placeholder="Telefone"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPhone(e.target.value)
            }
            value={phone}
            error={!isPhoneValid}
            helperText={!isPhoneValid && "O telefone deve conter 8 números"}
          />
          <TextField
            placeholder="Senha"
            type="password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
            value={password}
            error={!isPasswordValid}
            helperText={!isPasswordValid && passwordErrorMessage}
          />
        </SameLineInputsContainer>
        <InstructionsContainer style={{ marginBottom: "20px" }}>
          <InstructionTextContainer>
            <CheckMarkCreateNewPasswordRule />{" "}
            <InstructionText>Deve ter ao menos 8 caracteres</InstructionText>
          </InstructionTextContainer>

          <InstructionTextContainer>
            <CheckMarkCreateNewPasswordRule />{" "}
            <InstructionText>Deve conter ao menos uma letra</InstructionText>
          </InstructionTextContainer>

          <InstructionTextContainer>
            <CheckMarkCreateNewPasswordRule />{" "}
            <InstructionText>Deve conter ao menos um número</InstructionText>
          </InstructionTextContainer>

          <InstructionTextContainer>
            <CheckMarkCreateNewPasswordRule />{" "}
            <InstructionText>
              Deve conter ao menos um caractere especial
            </InstructionText>
          </InstructionTextContainer>

          <InstructionTextContainer>
            <CheckMarkCreateNewPasswordRule />{" "}
            <InstructionText>As duas senhas devem ser iguais</InstructionText>
          </InstructionTextContainer>
        </InstructionsContainer>

        {window.innerWidth > 400 && (
          <ModalSubtitle>Informações do contrato</ModalSubtitle>
        )}
        <SameLineInputsContainer>
          <TextField
            placeholder="Razão Social"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setRazaoSocial(e.target.value)
            }
            value={razaoSocial}
            error={!isRazaoSocialValid}
            helperText={
              !isRazaoSocialValid &&
              "A razão social deve conter mais de 2 caracteres"
            }
          />

          <div style={{ position: "relative" }}>
            {selectedAssessor.length < 1 && (
              <InputLabel
                style={{
                  position: "absolute",
                  bottom: "30px",
                }}
              >
                Assessor
              </InputLabel>
            )}
            <Select
              value={selectedAssessor}
              onChange={handleAssessorChange}
              error={!isAssessorSelectedValid}
            >
              {allAssessorsNames.map((assessorName, index) => {
                return (
                  <MenuItem
                    key={`${assessorName}+${index}`}
                    value={assessorName}
                  >
                    {assessorName}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </SameLineInputsContainer>

        <SameLineInputsContainer>
          <TextField
            placeholder="CNPJ"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCnpj(e.target.value)
            }
            value={cnpj}
            error={!isCnpjValid}
            helperText={
              !isCnpjValid && "O CNPJ da empresa deve conter 14 caracteres"
            }
          />
          <TextField
            placeholder="Tempo de contrato (em meses)"
            type="number"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setContractDurationInMonths((e.target.value as unknown) as number)
            }
            error={!isContractDurationValid}
            helperText={
              !isContractDurationValid &&
              "O contrato deve ter, no mínimo, 1 mês"
            }
          />
        </SameLineInputsContainer>

        <div style={{ position: "relative" }}>
          {selectedContractItems.length < 1 && (
            <InputLabel
              style={{
                position: "absolute",
                bottom: "30px",
              }}
            >
              Itens Contratados
            </InputLabel>
          )}
          <Select
            value={selectedContractItems}
            onChange={handleContractItemChange}
            multiple
            error={!areSelectedContractItemsValid}
          >
            {contractItems.map((contractItem, index) => {
              return (
                <MenuItem key={`${contractItem}+${index}`} value={contractItem}>
                  {contractItem}
                </MenuItem>
              );
            })}
          </Select>
        </div>

        {isCreatingClient ? (
          <CircularProgress style={{ alignSelf: "flex-end" }} />
        ) : (
          <AddButton onClick={handleClientCreation}>Adicionar</AddButton>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default AddClientModal;
