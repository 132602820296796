import { PasswordValidatorAdapter } from "../../../utils";

export function handlePasswordValidation(
  password: string,
  callback: Function
): boolean {
  const passwordValidator = new PasswordValidatorAdapter();
  const isPasswordValid = passwordValidator.isValid(password);
  callback(isPasswordValid);
  return isPasswordValid;
}
