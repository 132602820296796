import styled from "styled-components";

interface IIsCardFolderMobile {
  isMobile?: boolean;
}

export const CardContainer = styled.div<IIsCardFolderMobile>`
  width: 230px;
  height: 205px;
  position: relative;
  margin-right: 25px;
  cursor: pointer;

  @media only screen and (max-width: 1280px) {
    width: 150px;
    margin-right: 10px;
  }

  @media only screen and (max-width: 1024px) {
    height: fit-content;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const CardIconContainer = styled.div<IIsCardFolderMobile>`
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;

  @media only screen and (max-width: 1024px) {
    width: 150px;
    height: 100px;

    svg {
      width: 33.75px;
      height: 27.83px;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const CardTextContainer = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardText = styled.p`
  font-size: 20px;

  @media only screen and (max-width: 1280px) {
    font-size: 16px;
  }
`;

export const SectionMobileHeader = styled.h2`
  font: normal normal bold 18px "Open Sans", sans-serif;
  margin-bottom: 15px;
`;
