import React, { FormEvent, useState, useEffect, useCallback } from "react";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import {
  EmailSentPage,
  FormGroup,
  FormText,
  FormTitle,
  MainInfoContainer,
  IllustrationContainer,
  TextContainer,
  SendButton,
  CurrentSecondsMessage,
  SendButtonContainer,
} from "./EmailSent.elements";
import { EmailSentIllustration, LoginBackground } from "../../assets/svgs";
import { AxiosFunctions } from "../../services/api-integration";

let currentSecond = 30;

const EmailSent: React.FC = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [email, setEmail] = useState("");

  const countdown = useCallback((timerId: any) => {
    const messageElement = document.getElementById("current-seconds-message");

    if (currentSecond === -1) {
      clearTimeout(timerId);

      if (messageElement) messageElement.style.display = "none";
      setIsButtonDisabled(false);
    } else if (messageElement) {
      messageElement.innerHTML = `Aguarde ${currentSecond} ${
        currentSecond > 1 ? "segundos" : "segundo"
      }`;
      currentSecond--;
    }
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const apiInstance = new AxiosFunctions();

    await apiInstance.post({
      pathname: "session/password-reset/start",
      body: { email },
      onSuccess: () => {
        setIsLoading(false);
        toast.success(
          "O e-mail foi reenviado com sucesso, por favor, cheque sua caixa de e-mails"
        );
      },
      onError: () => {
        setIsLoading(false);
        toast.error(
          "Ocorreu um erro inesperado, por favor recarregue sua página e tente novamente"
        );
      },
    });
  };

  useEffect(() => {
    const timerId: any = setInterval(() => countdown(timerId), 1000);

    const clientEmail = sessionStorage.getItem("CLIENT_EMAIL");
    if (!clientEmail) return history.push("/esqueci-a-senha");

    setEmail(clientEmail);
  }, [countdown, history]);

  return (
    <EmailSentPage>
      <MainInfoContainer>
        <IllustrationContainer>
          <EmailSentIllustration />
        </IllustrationContainer>

        <FormGroup onSubmit={handleSubmit}>
          <TextContainer>
            <FormTitle>E-mail enviado</FormTitle>
            <FormText>
              Cheque seu e-mail, caso não tenha chegado, clique para enviar
              novamente
            </FormText>
          </TextContainer>

          {isLoading ? (
            <CircularProgress />
          ) : (
            <SendButtonContainer>
              <SendButton
                isDeactivated={isButtonDisabled}
                type="submit"
                disabled={isButtonDisabled}
              >
                Reenviar
              </SendButton>

              {currentSecond > 1 && (
                <CurrentSecondsMessage id="current-seconds-message">
                  Aguarde 30 segundos
                </CurrentSecondsMessage>
              )}
            </SendButtonContainer>
          )}
        </FormGroup>
      </MainInfoContainer>

      <LoginBackground id="background" />
    </EmailSentPage>
  );
};

export default EmailSent;
