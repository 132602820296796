import React, { ChangeEvent } from "react";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { LoginInputSignature } from "./LoginInput.signature";

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
      width: "100% !important",
    },
    "& .MuiFormControl": {
      width: "100% !important",
    },
  },
})(TextField);

const ErrorField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
      width: "100% !important",
    },
    "& .MuiFormControl": {
      width: "100% !important",
    },
  },
})(TextField);

const LoginInput: React.FC<LoginInputSignature> = ({
  label,
  type,
  onChange,
  isValid = true,
  value,
}) => {
  return (
    <>
      {isValid ? (
        <InputField
          label={label}
          id={label}
          data-testid={label}
          type={type}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
        />
      ) : (
        <ErrorField
          label={label}
          id={label}
          data-testid={label}
          type={type}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          error={true}
          helperText={
            label === "E-Mail"
              ? "É preciso ser inserido um e-mail válido"
              : "É preciso ser inserida uma senha válida"
          }
        />
      )}
    </>
  );
};

export default LoginInput;
