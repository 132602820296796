import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import { ListDocumentsPerWeek, AssessorFileView } from "../../components";
import { SectionContainer } from "./DocumentsPerWeek.elements";
import { AxiosFunctions } from "../../services";

const DocumentsPerWeek: React.FC = () => {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [weeksDataArray, setWeeksDataArray] = useState([
    { weekNumber: 0, weekData: [] as any },
  ]);

  const handleGetDeliveries = useCallback(async () => {
    const { advisoryId, week, month } = params as any;

    const apiInstance = new AxiosFunctions();

    const allDeliverables = await apiInstance.get({
      pathname: "deliverables",
      params: {
        advisoryId,
      },
    });

    let weeksInMonth = [
      { weekNumber: week - 4, weekData: [] as any },
      { weekNumber: week - 3, weekData: [] as any },
      { weekNumber: week - 2, weekData: [] as any },
      { weekNumber: week - 1, weekData: [] as any },
    ];

    for (let index = 0; index < weeksInMonth.length; index++) {
      weeksInMonth[index].weekData = allDeliverables.filter(
        (deliverable: any) => {
          return deliverable.week === weeksInMonth[index].weekNumber;
        }
      );
    }

    sessionStorage.setItem(
      `DELIVERABLES_${month}_${week}_${advisoryId}`,
      JSON.stringify(weeksInMonth)
    );
    setWeeksDataArray(weeksInMonth);

    setIsLoading(false);
  }, [params]);

  useEffect(() => {
    const { advisoryId, month, week } = params as any;

    const sessionStorageItem = sessionStorage.getItem(
      `DELIVERABLES_${month}_${week}_${advisoryId}`
    );

    if (!sessionStorageItem) handleGetDeliveries();
    else {
      setWeeksDataArray(JSON.parse(sessionStorageItem));

      setIsLoading(false);
    }
  }, [handleGetDeliveries, params]);

  const handleWeekFolderRender = (allDocumentsPerWeek: any) => {
    const listOfDocuments = [];

    for (let i = 0; i < allDocumentsPerWeek.length; i += 1) {
      listOfDocuments.push(
        <AssessorFileView
          key={allDocumentsPerWeek[i].guid}
          content={allDocumentsPerWeek[i].content}
          name={allDocumentsPerWeek[i].title}
          category={allDocumentsPerWeek[i].type}
          id={allDocumentsPerWeek[i].guid}
        />
      );
    }

    return listOfDocuments;
  };

  return (
    <SectionContainer>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        weeksDataArray.map((week, index) => {
          return (
            week.weekNumber >= 0 && (
              <ListDocumentsPerWeek
                chevronId={`chevron-${week.weekNumber}`}
                infoId={`info-${week.weekNumber}`}
                weekNumber={(week.weekNumber % 4) + 1}
                key={week.weekNumber}
              >
                {handleWeekFolderRender(week.weekData)}
              </ListDocumentsPerWeek>
            )
          );
        })
      )}
    </SectionContainer>
  );
};

export default DocumentsPerWeek;
