import React, { useCallback, useEffect, useState } from "react";
import {
  Chip,
  Input,
  InputLabel,
  Select,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import {
  withStyles,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { toast } from "react-toastify";

import {
  SectionContainer,
  SectionHeader,
  InfoContainer,
  InputsContainer,
} from "./ProfileContractInfo.elements";
import { ProfileContractInfoSignature } from "./ProfileContractInfo.signature";
import { AxiosFunctions } from "../../services/api-integration";

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
  },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const ProfileContractInfo: React.FC<ProfileContractInfoSignature> = ({
  isPasswordChanging,
  advisoryId,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [companyInfo, setCompanyInfo] = useState<{
    cnpj: string;
    companyName: string;
  }>({
    cnpj: "",
    companyName: "",
  });
  const [contractedItems, setContractedItems] = useState<string[]>([""]);

  const classes = useStyles();

  const handleGettingData = useCallback(async () => {
    const apiInstance = new AxiosFunctions();

    const advisoryData = await apiInstance.get({
      pathname: `advisory-services/${advisoryId}`,
      onError: () => {
        toast.error(
          "Ocorreu um erro inesperado, por favor recarregue sua página e tente novamente"
        );

        setIsLoading(false);
      },
    });

    if (advisoryData) {
      const { clients, contractId, guid } = advisoryData;

      const newCompanyInfo = {
        cnpj: clients[0].cnpj,
        companyName: clients[0].companyName,
      };

      setCompanyInfo(newCompanyInfo);

      const contractData = await apiInstance.get({
        pathname: `contracts/${contractId}`,
        params: {
          advisoryId: guid,
        },
        onError: () => {
          toast.error(
            "Ocorreu um erro inesperado, por favor recarregue sua página e tente novamente"
          );

          setIsLoading(false);
        },
      });

      if (contractData) {
        setContractedItems(contractData.contractedItems);

        const resumedData = {
          cnpj: clients[0].cnpj,
          companyName: clients[0].companyName,
          items: contractData["contractedItems"],
        };

        sessionStorage.setItem(
          `CONTRACT_INFO_ID_${advisoryId}`,
          JSON.stringify(resumedData)
        );
      }
    }

    setIsLoading(false);
  }, [advisoryId]);

  useEffect(() => {
    const contractInfo = sessionStorage.getItem(
      `CONTRACT_INFO_ID_${advisoryId}`
    );

    if (!contractInfo) handleGettingData();
    else {
      const data = JSON.parse(contractInfo);

      setContractedItems(data.items);

      const companyData = {
        cnpj: data.cnpj,
        companyName: data.companyName,
      };

      setCompanyInfo(companyData);

      setIsLoading(false);
    }
  }, [advisoryId, handleGettingData]);

  return (
    <SectionContainer>
      <SectionHeader id="teste">Informações do contrato</SectionHeader>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <InfoContainer>
          <InputsContainer>
            <InputField
              placeholder="Razão Social"
              label="Razão Social"
              value={companyInfo.companyName}
              disabled
            />

            <InputField
              placeholder="CNPJ"
              label="CNPJ"
              value={companyInfo.cnpj}
              disabled
            />
          </InputsContainer>

          <div>
            <InputLabel style={{ transform: "scale(0.75)", color: "black" }}>
              Itens Contratados
            </InputLabel>
            <Select
              multiple
              value={contractedItems}
              disabled
              style={{ width: "300px" }}
              input={<Input />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as string[]).map((value) => (
                    <Chip
                      style={{
                        backgroundColor: isPasswordChanging
                          ? "#DEDEDE"
                          : "#B4C2F2",
                      }}
                      key={value}
                      label={value}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
            ></Select>
          </div>
        </InfoContainer>
      )}
    </SectionContainer>
  );
};

export default ProfileContractInfo;
