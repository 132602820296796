import React, { ChangeEvent, useState } from "react";
import { Modal, TextField, CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";

import { IModal } from "./GenericModalInterface";
import {
  ModalContainer,
  CloseIconContainer,
  ModalTitle,
  ModalSubtitle,
  SameLineInputsContainer,
  AddButton,
} from "./GenericModalStyles.style";
import { CloseIcon } from "../../assets/svgs";
import { AxiosFunctions } from "../../services/api-integration";
import { PasswordValidatorAdapter } from "../../utils";

interface IAddAssessorModal extends IModal {
  triggerPageReload: Function;
}

const AddAssessorModal: React.FC<IAddAssessorModal> = ({
  handleClose,
  open,
  triggerPageReload,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [isCreatingAssessor, setIsCreatingAssessor] = useState(false);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const handleInputsValidation = () => {
    if (name.length < 3) {
      setIsNameValid(false);
      setIsCreatingAssessor(false);
      return false;
    }

    if (!email.includes("@") || !email.includes(".")) {
      setIsEmailValid(false);
      setIsCreatingAssessor(false);
      return false;
    }

    if (phone.length < 8) {
      setIsPhoneValid(false);
      setIsCreatingAssessor(false);
      return false;
    }

    if (password.length < 8) {
      setIsPasswordValid(false);
      setIsCreatingAssessor(false);
      return false;
    }

    const passwordValidator = new PasswordValidatorAdapter();
    const isPasswordValid = passwordValidator.isValid(password);

    if (!isPasswordValid) {
      setIsPasswordValid(false);
      setIsCreatingAssessor(false);
      return false;
    }

    return true;
  };

  const handleAssessorCreation = async () => {
    setIsCreatingAssessor(true);

    const apiInstance = new AxiosFunctions();

    const areInputsValid = handleInputsValidation();

    if (!areInputsValid) return;

    const body = {
      name,
      email,
      password,
      phone,
    };

    await apiInstance.post({
      pathname: "users/advisor",
      body,
      onSuccess: () => {
        setName("");
        setEmail("");
        setPassword("");
        setPhone("");
        setIsCreatingAssessor(false);

        sessionStorage.removeItem("ADMIN_HOME_INFO");

        triggerPageReload(true);
        handleClose(false);
        toast.success("Assessor criado com sucesso!");
      },
      onError: (err: any) => {
        if (err.status === 400) {
          toast.error("Houve um erro, e-mail já cadastrado");
        } else {
          toast.error(
            "Houve um problema inesperado, por favor tente novamente"
          );
        }
        setIsCreatingAssessor(false);

        handleClose(false);
      },
    });
  };

  return (
    <Modal open={open} style={{ zIndex: 9000 }}>
      <ModalContainer
        style={{ height: window.innerWidth < 825 ? "fit-content" : "380px" }}
      >
        <CloseIconContainer onClick={() => handleClose(false)}>
          <CloseIcon />
        </CloseIconContainer>

        <ModalTitle>Adicionar assessor</ModalTitle>

        <ModalSubtitle>Informações da conta</ModalSubtitle>
        <SameLineInputsContainer>
          <TextField
            placeholder="Nome"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
            error={!isNameValid}
            helperText={
              !isNameValid && "O nome deve conter mais de 2 caracteres"
            }
            value={name}
          />
          <TextField
            placeholder="E-mail"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            error={!isEmailValid}
            helperText={!isEmailValid && "O e-mail deve ser um e-mail válido"}
            value={email}
          />
        </SameLineInputsContainer>

        <SameLineInputsContainer>
          <TextField
            placeholder="Telefone"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPhone(e.target.value)
            }
            error={!isPhoneValid}
            helperText={!isPhoneValid && "O telefone deve conter 8 números"}
            value={phone}
          />
          <TextField
            placeholder="Senha"
            type="password"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
            error={!isPasswordValid}
            helperText={
              !isPasswordValid &&
              "A senha deve conter 8 caracteres, letras, números e caracteres especiais"
            }
            value={password}
          />
        </SameLineInputsContainer>

        {isCreatingAssessor ? (
          <CircularProgress style={{ alignSelf: "flex-end" }} />
        ) : (
          <AddButton onClick={handleAssessorCreation}>Adicionar</AddButton>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default AddAssessorModal;
