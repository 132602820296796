import styled from "styled-components";

export const SectionContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 25px;

  .MuiSvgIcon-root,
  .MuiSelect-icon {
    display: none !important;
  }
`;

export const SectionHeader = styled.h2`
  margin-bottom: 35px;

  @media only screen and (max-width: 700px) {
    font-size: 1.2rem;
    margin: 25px 0 15px 0;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 600px;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const InputsContainer = styled.div`
  max-height: 125px;
  min-height: 125px;
  width: 287.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 25px;

  @media only screen and (max-width: 700px) {
    margin-bottom: 35px;
    width: 100%;
  }
`;
