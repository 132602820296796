import styled from "styled-components";

export const PreviewContainer = styled.div`
  height: fit-content;
  width: 450px;
  padding: 10px;
  border: 1.5px solid black;

  @media only screen and (max-width: 1440px) {
    width: 375px;
  }

  @media only screen and (max-width: 1280px) {
    width: 275px;
  }

  @media only screen and (max-width: 1024px) {
    width: 450px;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const TopInfo = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ProfileImageContainer = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dddddd;
  margin-right: 10px;
  overflow: hidden;

  svg {
    height: 24px;
    width: 24px;
  }

  @media only screen and (max-width: 1280px) {
    width: 45px;
    height: 45px;
  }
`;

export const AssessorName = styled.h2`
  font: normal normal bold 25px "Open Sans", sans-serif;
  color: #ed931f;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: 1440px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 600px) {
    max-width: calc(100% - 60px);
  }
`;

export const AbilitiesContainer = styled.div`
  max-height: 125px;
  position: relative;
`;

export const AbilitiesTitle = styled.p`
  font: normal normal normal 20px "Open Sans", sans-serif;
  margin: 15px 0 10px 0;

  @media only screen and (max-width: 1440px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 1024px) {
    margin: 3px 0 2.5px 0;
  }
`;

export const AbilitiesList = styled.ul`
  list-style: none;
  width: 100%;
  max-height: 300px;
  display: flex;
  flex-wrap: wrap;
`;

export const AbilityItem = styled.li`
  text-decoration: none;
  width: 103px;
  background-color: #b3c2f2;
  border-radius: 20px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 3px;
  font-size: 16px;

  @media only screen and (max-width: 1440px) {
    width: 85px;
    font-size: 14px;
  }

  @media only screen and (max-width: 1370px) {
    width: 61px;
    font-size: 10px;
  }
`;
