import styled from "styled-components";

export const LoginPage = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #031326;
  z-index: -2;
  overflow: hidden !important;
  position: relative;

  #background {
    position: absolute;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg:first-child {
    width: 325px;
  }

  svg {
    width: 160px;
  }

  @media only screen and (max-width: 500px) {
    svg {
      max-width: 230px;
    }
  }
`;

export const MainInfoContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fbfbfb;
  min-height: 800px;
  min-width: 600px;
  padding-top: 100px;
  z-index: 2;

  @media only screen and (max-height: 780px) {
    min-height: 600px;
  }

  @media only screen and (max-width: 500px) {
    min-width: 340px;
    min-height: 90%;
  }

  @media only screen and (max-width: 320px) {
    min-width: 300px;
    padding-top: 50px;
  }
`;

export const ForgotPasswordAnchor = styled.a`
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
`;
