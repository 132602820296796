import React from "react";

import {
  PreviewContainer,
  TopInfo,
  ProfileImageContainer,
  AssessorName,
} from "./AssessorPreview.elements";
import { UserDefaultIcon } from "../../assets/svgs";

interface IAssessor {
  name: string;
  avatar: string;
}

const AssessorPreview: React.FC<IAssessor> = ({ name, avatar }) => {
  return (
    <PreviewContainer>
      <TopInfo>
        <ProfileImageContainer>
          {avatar ? (
            <img
              src={avatar}
              alt="Foto de perfil do assessor"
              style={{ height: "inherit" }}
            />
          ) : (
            <UserDefaultIcon />
          )}
        </ProfileImageContainer>

        <AssessorName>{name}</AssessorName>
      </TopInfo>
    </PreviewContainer>
  );
};

export default AssessorPreview;
