import React, { ChangeEvent, useState, useEffect } from "react";

import {
  SectionContainer,
  InputContainer,
  SearchBar,
} from "./AssessorHome.elements";
import { ClientsList } from "../../components";
import { AZFilter } from "../../assets/svgs";
import { AxiosFunctions } from "../../services/api-integration";

const AssessorHome: React.FC = () => {
  const [allClients, setAllClients] = useState<Array<any>>([]);
  const [clientsToShow, setClientsToShow] = useState(allClients);
  const [isLoading, setIsLoading] = useState(true);

  const handleClientsSearch = (e: ChangeEvent<HTMLInputElement>) => {
    let clientsToShowArray: any = [];
    const searchedTerm = e.target.value;

    if (searchedTerm) {
      allClients.map((client) => {
        if (client.name.toLowerCase().includes(searchedTerm.toLowerCase()))
          return clientsToShowArray.push(client);
        return -1;
      });
    } else {
      clientsToShowArray = [...allClients];
    }
    setClientsToShow(clientsToShowArray);
  };

  const handleClientsFilter = () => {
    const clientsOrderedAlphabetically = [
      ...clientsToShow,
    ].sort((clientA, clientB) => clientA.name.localeCompare(clientB.name));

    setClientsToShow(clientsOrderedAlphabetically);
  };

  const handleGetClients = async () => {
    const apiInstance = new AxiosFunctions();

    const myAccessoryServices = await apiInstance.get({
      pathname: "advisory-services",
      params: {
        includeUsers: true,
      },
    });

    if (myAccessoryServices) {
      const clientsFromMyAccessoryServices = myAccessoryServices.map(
        (accessoryService: any) => accessoryService.clients[0]
      );

      const resumedData = {
        clients: clientsFromMyAccessoryServices,
      };

      sessionStorage.setItem("ADVISOR_HOME_INFO", JSON.stringify(resumedData));

      setAllClients(clientsFromMyAccessoryServices);
      setClientsToShow(clientsFromMyAccessoryServices);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const sessionStorageItem = sessionStorage.getItem("ADVISOR_HOME_INFO");

    if (!sessionStorageItem) handleGetClients();
    else {
      const { clients } = JSON.parse(sessionStorageItem);

      setAllClients(clients);
      setClientsToShow(clients);
      setIsLoading(false);
    }
  }, []);

  return (
    <SectionContainer>
      <InputContainer>
        <SearchBar
          placeholder="Search..."
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleClientsSearch(e)
          }
        />
        <AZFilter onClick={handleClientsFilter} />
      </InputContainer>

      {clientsToShow && (
        <ClientsList clientsToShow={clientsToShow} isLoading={isLoading} />
      )}
    </SectionContainer>
  );
};

export default AssessorHome;
