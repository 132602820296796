import styled from "styled-components";

export const SectionContainer = styled.section`
  width: 100%;
  height: auto;
  margin-bottom: 50px;
`;

export const SectionTopInfo = styled.header`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  svg {
    transform: rotate(270deg);
    margin-right: 25px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  @media only screen and (max-width: 700px) {
    margin-bottom: 15px;
  }
`;

export const SectionHeader = styled.h2`
  font: normal normal bold 20px "Open Sans", sans-serif;

  @media only screen and (max-width: 700px) {
    font-size: 18px;
  }
`;

export const SectionsDivisor = styled.span`
  height: 1px;
  width: calc(100% - 175px);
  background-color: black;
  position: absolute;
  right: 0;

  @media only screen and (max-width: 700px) {
    width: calc(100% - 175px);
  }
`;

export const MainInfo = styled.main`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 700px) {
    justify-content: space-evenly;
  }
`;
