import styled from "styled-components";

export const SectionForm = styled.form`
  height: 325px;
  width: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;

  @media only screen and (max-width: 600px) {
    width: 260px;
  }
`;

export const FormTitle = styled.h1``;

export const SignInButton = styled.button`
  background-color: #b3c2f2;
  width: 120px;
  height: 40px;
  border: 0px solid #031326;
  box-shadow: 0px 3px #00000029;
  transition: background-color 0.1s ease;

  :hover {
    background-color: #9eabd5;
  }
`;
