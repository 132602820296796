import React, { ChangeEvent, FormEvent, useState } from "react";
import {
  Modal,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { toast } from "react-toastify";

import {
  ModalContainer,
  ModalInputInfo,
  ModalHeader,
  InputsContainer,
  ClientName,
  UploadButton,
  UploadButtonContainer,
  FileInput,
  CloseIconContainer,
  SubmitButton,
} from "./AddDocumentModal.elements";
import { UploadIcon, CloseIcon } from "../../assets/svgs";
import { AxiosFunctions } from "../../services/api-integration";

interface IModalProps {
  clientName: string;
  isOpen: boolean;
  handleModalClose: Function;
  contractedItems: string[];
  monthsOfTheContract: string[];
  advisoryId: string;
  contractStartDate: Date | undefined;
  triggerPageLoading: Function;
  contractId: string;
}

const AddDocumentModal: React.FC<IModalProps> = ({
  clientName,
  isOpen,
  handleModalClose,
  contractedItems,
  monthsOfTheContract,
  advisoryId,
  contractStartDate,
  triggerPageLoading,
  contractId,
}) => {
  const [month, setMonth] = useState("");
  const [week, setWeek] = useState("");
  const [category, setCategory] = useState("");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = () => {
    const fileInput = document.getElementById("file-input");
    fileInput?.click();
  };

  const handleFieldsValidation = () => {
    if (!month) {
      toast.error("Você deve escolher um mês");
      return false;
    }

    if (!week) {
      toast.error("Você deve escolher uma semana");
      return false;
    }

    if (!category) {
      toast.error("Você deve escolher uma categoria");
      return false;
    }

    if (!selectedFile) {
      toast.error("Você deve carregar um arquivo");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const selectedMonth = monthsOfTheContract.indexOf(month);

    const deliverableWeek = selectedMonth * 4 + Number(week) - 1;

    const areFieldsValid = handleFieldsValidation();

    if (!areFieldsValid) return setIsLoading(false);

    const apiInstance = new AxiosFunctions();

    const body = {
      title: selectedFile.name,
      type: category,
    };

    // Creating deliverable
    const deliverableCreated = await apiInstance.post({
      pathname: "deliverables",
      body,
      params: {
        week: deliverableWeek,
        advisoryId,
      },
    });

    // Sending file
    const formData = new FormData();
    formData.append("file", selectedFile);
    await apiInstance.post({
      pathname: `deliverables/${deliverableCreated.guid}/content`,
      body: formData,
      params: {
        advisoryId,
      },
      onSuccess: () => {
        setMonth("");
        setCategory("");
        setWeek("");
        setSelectedFile(null);

        apiInstance.post({
          pathname: `deliverables/${deliverableCreated.guid}/notifications?advisoryId=${advisoryId}`,
          body: {
            year: month.split(" - ")[1],
            week,
            month: month.split(" - ")[0],
            contractWeek: (selectedMonth + 1) * 4,
          },
        });

        sessionStorage.removeItem(
          `DELIVERABLES_${month.split(" - ")[0].toLocaleLowerCase()}_${
            (selectedMonth + 1) * 4
          }_${advisoryId}`
        );

        sessionStorage.removeItem(`THIS_WEEK_DELIVERABLES_${contractId}`);

        toast.success("Documento adicionado à assessoria com sucesso!");

        handleModalClose(false);
        triggerPageLoading(true);
        setIsLoading(false);
      },
      onError: () => {
        toast.error(
          "Ocorreu um erro inesperado, por favor recarregue sua página e tente novamente"
        );

        handleModalClose(false);
        setIsLoading(false);
      },
    });
  };

  return (
    <Modal open={isOpen} onClose={() => handleModalClose(false)}>
      <ModalContainer onSubmit={handleSubmit}>
        <ModalInputInfo>
          <ModalHeader>Adicionar documento</ModalHeader>

          <ClientName>{clientName}</ClientName>

          <InputsContainer>
            <FormControl>
              <InputLabel id="month-input-label">Mês</InputLabel>
              <Select
                labelId="month-input-label"
                id="month-input-select"
                value={month}
                onChange={(e) => setMonth(String(e.target.value))}
                style={{ width: window.innerWidth > 600 ? "200px" : "100%" }}
              >
                (
                {monthsOfTheContract.map((month: string) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
                )
              </Select>
            </FormControl>

            <FormControl id="form-control-width">
              <InputLabel id="week-input-label">Semana</InputLabel>
              <Select
                labelId="week-input-label"
                id="week-input-select"
                value={week}
                onChange={(e) => setWeek(String(e.target.value))}
                style={{ width: "100px" }}
              >
                <MenuItem value={"1"}>01</MenuItem>
                <MenuItem value={"2"}>02</MenuItem>
                <MenuItem value={"3"}>03</MenuItem>
                <MenuItem value={"4"}>04</MenuItem>
              </Select>
            </FormControl>
          </InputsContainer>

          <FormControl>
            <InputLabel id="category-input-label">Categoria</InputLabel>
            <Select
              labelId="category-input-label"
              id="category-input-select"
              value={category}
              onChange={(e) => setCategory(String(e.target.value))}
              style={{ width: window.innerWidth > 600 ? "375px" : "100%" }}
            >
              (
              {contractedItems.map((item: string) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
              )
            </Select>
          </FormControl>
        </ModalInputInfo>

        <CloseIconContainer onClick={() => handleModalClose(false)}>
          <CloseIcon />
        </CloseIconContainer>

        <UploadButtonContainer>
          <UploadButton onClick={handleFileUpload} type="button">
            <FileInput
              type="file"
              id="file-input"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.files) {
                  setSelectedFile(e.target.files[0]);
                }
              }}
            />

            {selectedFile ? (
              <>
                <p style={{ width: "75%" }}>{selectedFile.name}</p>
              </>
            ) : (
              <UploadIcon />
            )}
          </UploadButton>

          {isLoading ? (
            <div
              style={{ position: "absolute", bottom: "20px", right: "60px" }}
            >
              <CircularProgress />
            </div>
          ) : (
            <SubmitButton type="submit">Enviar</SubmitButton>
          )}
        </UploadButtonContainer>
      </ModalContainer>
    </Modal>
  );
};

export default AddDocumentModal;
