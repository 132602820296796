import styled from "styled-components";

export const ClientCardsContainer = styled.main`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const ClientCard = styled.div`
  width: calc((100% - 30%) / 3);
  height: 135px;
  background-color: #fbfbfb;
  box-shadow: 0px 3px 6px #00000029;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  margin-right: 20px;
  cursor: pointer;

  @media only screen and (max-width: 1024px) {
    width: 240px;
    height: 80px;
    margin-right: 10px;
  }
`;

export const ClientImage = styled.img`
  width: 90px;
  height: 90px;
  border: 0.25px solid #031326;
  border-radius: 50%;

  @media only screen and (max-width: 1024px) {
    width: 48px;
    height: 48px;
  }
`;

export const ClientInfo = styled.main`
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 225px;

  @media only screen and (max-width: 1600px) {
    max-width: 185px;
  }

  @media only screen and (max-width: 1440px) {
    max-width: 145px;
  }

  @media only screen and (max-width: 1440px) {
    max-width: 125px;
  }

  @media only screen and (max-width: 1024px) {
    height: 45px;
  }
`;

export const ClientName = styled.h1`
  font: normal normal 600 20px/20px "Open Sans", sans-serif;
  color: #031326;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: 1024px) {
    font: normal normal 600 15px/20px "Open Sans", sans-serif;
  }
`;

export const ClientContractInfo = styled.h2`
  text-align: center;
  font: normal normal normal 16px/16px "Open Sans", sans-serif;
  letter-spacing: 0px;
  color: #031326;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (max-width: 1024px) {
    font: normal normal normal 12px/16px "Open Sans", sans-serif;
  }
`;
