import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import {
  Login,
  UnderConstruction,
  Profile,
  DocumentsPerWeek,
  AssessorHome,
  AssessorClientView,
  AdminHome,
  AssessorProfileAdminView,
  ForgotPassword,
  EmailSent,
  CreateNewPassword,
  ClientProfileAdminView,
} from "../views";
import { AppContext } from "../services";

const Routes: React.FC = () => {
  const { userRole } = useContext(AppContext);
  const homeComponentHandler = () => {
    const sessionStorageItem = sessionStorage.getItem("USER_ROLE");

    if (userRole === "ADMIN" || sessionStorageItem === "ADMIN")
      return AdminHome;
    if (userRole === "ADVISOR" || sessionStorageItem === "ADVISOR")
      return AssessorHome;
    if (userRole === "CLIENT" || sessionStorageItem === "CLIENT")
      return AssessorClientView;

    return Login;
  };

  return (
    <Switch>
      <PrivateRoute
        path="/em-construcao"
        component={UnderConstruction}
        isPrivate
      />
      <PrivateRoute
        exact
        path="/"
        component={homeComponentHandler()}
        isPrivate
      />
      <PrivateRoute path="/perfil" component={Profile} isPrivate />
      <Route path="/login/:next" component={Login} />
      <Route path="/login" component={Login} />
      <PrivateRoute
        path="/cliente/perfil/:clientName/:id"
        component={ClientProfileAdminView}
        isPrivate
      />
      <PrivateRoute
        path="/cliente/:clientName/:id"
        component={AssessorClientView}
        isPrivate
      />
      <PrivateRoute
        path="/assessor/:assessorName/:id"
        component={AssessorProfileAdminView}
        isPrivate
      />
      <PrivateRoute
        path="/visualizacao-semanal/:year/:month/:week/:advisoryId"
        component={DocumentsPerWeek}
        isPrivate
      />
      <Route
        path="/esqueci-a-senha/alterar/:token"
        component={CreateNewPassword}
      />
      <Route path="/esqueci-a-senha/reenviar-email" component={EmailSent} />
      <Route path="/esqueci-a-senha" component={ForgotPassword} />
      <Route>
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
};

export default Routes;
