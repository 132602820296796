import { ParserFactory } from "./folderNameParserAdapter.signature";

class NameParserFactory implements ParserFactory {
  parseName(rawName: string): string {
    const splittedString = rawName.split(" ");
    const month = splittedString[0].toLowerCase();
    const year = splittedString[2];
    const yearSlashMonth = `${year}/${month}`;

    return yearSlashMonth;
  }
}

export default new NameParserFactory();
