import React from "react";

import {
  CardContainer,
  CardTextContainer,
  CardText,
  CardMainContent,
  DownloadTrigger,
} from "./AssessorFileView.elements";
import { DownloadIcon } from "../../assets/svgs";

interface IAssessorFileView {
  name?: string;
  category?: string;
  content?: string;
  id?: string;
}

const AssessorFileView: React.FC<IAssessorFileView> = ({
  name,
  category,
  content,
  id,
}) => {
  const contentId = id ? id : "download-trigger";

  const handleDownload = () => {
    const downloadTrigger = document.getElementById(contentId);
    downloadTrigger?.click();
  };

  return (
    <CardContainer onClick={handleDownload}>
      <CardMainContent>
        <p
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {name}
        </p>
      </CardMainContent>
      <CardTextContainer>
        <CardText>{category}</CardText>

        <DownloadTrigger
          id={contentId}
          href={content}
          download={name}
          target="_blank"
        >
          <DownloadIcon />
        </DownloadTrigger>
      </CardTextContainer>
    </CardContainer>
  );
};

export default AssessorFileView;
