import { ValidatorFactory } from "./passwordValidatorAdapter.signature";

class PasswordValidatorFactory implements ValidatorFactory {
  isPassword(password: string) {
    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?_&])[A-Za-z\d@$!%*#?_&]{8,}$/;
    const result = password.match(passwordPattern);
    if (result) return true;
    return false;
  }
}

const passwordValidatorFactory = new PasswordValidatorFactory();
export default passwordValidatorFactory;
