import styled from "styled-components";

export const ModalContainer = styled.form`
  position: absolute;
  top: calc(50% - 175px);
  left: calc(50% - 400px);
  width: 800px;
  height: 350px;
  background-color: #fbfbfb;
  outline: none;
  display: flex;

  #form-control-width {
    width: fit-content !important;
  }

  @media only screen and (max-width: 800px) {
    width: 600px;
    left: calc(50% - 300px);
  }

  @media only screen and (max-width: 600px) {
    width: 300px;
    left: calc(50% - 150px);
    flex-direction: column;
    height: 500px;
    top: calc(50% - 250px);

    #category-input-select {
      width: 100%;
    }
  }
`;

export const ModalInputInfo = styled.main`
  width: 400px;
  height: 350px;
  padding: 25px;
  position: relative;

  @media only screen and (max-width: 600px) {
    width: 300px;
  }
`;

export const ModalHeader = styled.h1`
  font: normal normal bold 20px "Open Sans", sans-serif;
`;

export const ClientName = styled.p`
  font-weight: 700;
  margin-top: 35px;
  margin-bottom: 15px;
`;

export const InputsContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;

  @media only screen and (max-width: 800px) {
    justify-content: space-between;

    &:first-child {
      width: 200px;
    }

    &:nth-child(1) {
      width: 100px;
    }
  }

  @media only screen and (max-width: 600px) {
    .MuiFormControl-root {
      width: 125px !important;
    }
  }
`;

export const UploadButtonContainer = styled.aside`
  width: 400px;
  height: 350px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding: 10px;

  @media only screen and (max-width: 600px) {
    width: 300px;
    justify-content: flex-start;
  }
`;

export const UploadButton = styled.button`
  width: 75%;
  height: 50%;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border: 1px solid #000;

  @media only screen and (max-width: 600px) {
    height: 65%;
  }
`;

export const FileInput = styled.input`
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  position: absolute;
  display: none;
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 10;
`;

export const SubmitButton = styled.button`
  background-color: #b3c2f2;
  width: 120px;
  height: 40px;
  border: 0px solid #031326;
  box-shadow: 0px 3px #00000029;
  transition: background-color 0.1s ease;
  position: absolute;
  bottom: 20px;
  right: 60px;

  :hover {
    background-color: #9eabd5;
  }

  @media only screen and (max-width: 800px) {
    right: 30px;
  }

  @media only screen and (max-width: 600px) {
    right: 45px;
  }
`;
