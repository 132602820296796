import React from "react";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
  SectionContainer,
  SectionHeader,
  InputsContainer,
} from "./ProfileAccountInfo.elements";
import { IProfileAccountInfo } from "./ProfileAccountInfo.signature";

const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
  },
})(TextField);

const ProfileAccountInfo: React.FC<IProfileAccountInfo> = ({
  email,
  name,
  phone,
  isInputDeactivated = true,
  setName,
  setPhone,
  setNewPassword,
  setOldPassword,
  newPassword,
  oldPassword,
}) => {
  return (
    <SectionContainer>
      <SectionHeader>Informações da conta</SectionHeader>
      <InputsContainer>
        <InputField
          placeholder="Nome"
          label="Nome"
          value={name}
          disabled={isInputDeactivated}
          onChange={(e) => setName && setName(e.target.value)}
          style={{ gridArea: "name" }}
        />
        <InputField
          placeholder="E-Mail"
          label="E-Mail"
          value={email}
          disabled={isInputDeactivated}
          style={{ gridArea: "email" }}
        />
        <InputField
          placeholder="Telefone"
          label="Telefone"
          value={phone}
          disabled={isInputDeactivated}
          onChange={(e) => setPhone && setPhone(e.target.value)}
          style={{ gridArea: "phone" }}
        />
        <InputField
          placeholder="Senha Atual"
          label="Senha Atual"
          value={oldPassword}
          type="password"
          disabled={isInputDeactivated}
          onChange={(e) => setOldPassword && setOldPassword(e.target.value)}
          style={{ gridArea: "oldPassword" }}
        />

        {!isInputDeactivated && (
          <InputField
            placeholder="Nova Senha"
            label="Nova Senha"
            value={newPassword}
            type="password"
            onChange={(e) => setNewPassword && setNewPassword(e.target.value)}
            style={{ gridArea: "newPassword" }}
          />
        )}
      </InputsContainer>
    </SectionContainer>
  );
};

export default ProfileAccountInfo;
