import styled from "styled-components";

interface IModalContainerAdaptiveWindowSize {
  shouldAdaptToWindow?: boolean;
}

export const ModalContainer = styled.section<IModalContainerAdaptiveWindowSize>`
  position: absolute;
  top: calc(50% - 280px);
  left: calc(50% - 524px);
  width: 1048px;
  height: 560px;
  background-color: #f8f8f8;
  padding: 30px;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-y: scroll;

  .MuiInput-root {
    width: 450px !important;
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 1024px) {
    width: 500px;
    left: calc(50% - 250px);
    top: calc(50% - 47%);
    height: fit-content;
  }

  @media only screen and (max-width: 600px) {
    width: 300px;
    left: calc(50% - 150px);
    height: 95%;

    .MuiInput-root {
      width: 240px !important;
    }
  }

  @media only screen and (max-width: 400px) {
    padding: 10px 30px;
  }

  @media only screen and (max-width: 420px) and (max-height: 745px) {
    padding-top: ${(props) => (props.shouldAdaptToWindow ? "100px" : "")};
  }

  @media only screen and (max-width: 420px) and (max-height: 700px) {
    padding-top: ${(props) => (props.shouldAdaptToWindow ? "230px" : "")};
  }

  @media only screen and (min-width: 1280px) {
    padding-top: ${(props) => (props.shouldAdaptToWindow ? "100px" : "")};
  }
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;

  @media only screen and (max-width: 400px) {
    top: 10px;
    right: 20px;
  }
`;

export const ModalTitle = styled.h1`
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #031326;
  margin-bottom: 24px;

  @media only screen and (max-width: 400px) {
    font-size: 20px;
    margin-bottom: 12px;
  }
`;

export const ModalSubtitle = styled.h2`
  font-size: 25px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #031326;
  margin-bottom: 24px;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 12px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 16px;
    margin-bottom: 8px;
  }
`;

export const SameLineInputsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    width: 450px !important;

    .MuiFormHelperText-root .Mui-error,
    p {
      position: absolute;
      top: -20px;
    }
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 600px) {
    & > div {
      width: 240px !important;
    }
  }
`;

export const AddButton = styled.button`
  border: none;
  padding: 5px 15px;
  width: 120px;
  height: 40px;
  background-color: #b3c2f2;
  box-shadow: 0px 3px 6px #00000029;
  transition: all 200ms ease-in-out;
  font: normal normal normal 16px "Open Sans", sans-serif;
  cursor: pointer;
  align-self: flex-end;

  &:hover {
    background-color: #91a8f2;
  }
`;

export const CancelButton = styled.button`
  border: none;
  padding: 5px 15px;
  width: 120px;
  height: 40px;
  background-color: #f0f3ff;
  box-shadow: 0px 3px 6px #00000029;
  transition: all 200ms ease-in-out;
  font: normal normal normal 16px "Open Sans", sans-serif;
  cursor: pointer;
  align-self: flex-end;
  margin-right: 50px;

  &:hover {
    background-color: #d6e0ff;
  }
`;
