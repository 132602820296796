import styled from "styled-components";

export const ProfileSection = styled.section`
  height: fit-content;
  min-height: 100%;
  width: 100vw;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: 700px) {
    position: relative;
  }
`;

export const MainSection = styled.main`
  width: 50%;
  height: fit-content;

  @media only screen and (max-width: 700px) {
    width: 80%;
  }
`;

export const ProfileBasicInfo = styled.div`
  display: flex;
  width: 415px;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 215px;
  }
`;

export const GreetingMessages = styled.div`
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 700px) {
    width: 210px;
  }
`;

export const Greeting = styled.h2`
  color: #ed942b;

  @media only screen and (max-width: 700px) {
    margin-top: 15px;
    font-size: 1rem;
  }
`;

export const ContractTimeRemaining = styled.h3`
  font-weight: normal;

  @media only screen and (max-width: 700px) {
    font-size: 0.9rem;
  }
`;

export const ProfileImage = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  padding: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dddddd;

  svg {
    height: 24px;
    width: 24px;
  }

  @media only screen and (max-width: 700px) {
    height: 80px;
    width: 80px;
    padding: 24px;
  }
`;

export const IconsAside = styled.aside`
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 700px) {
    width: 20%;
    min-height: 560px;
    margin-bottom: 25px;

    svg {
      width: 18px;
    }
  }

  @media only screen and (max-width: 600px) {
    height: ${() => {
      const profilePage = document.getElementById("section-container");
      const height = profilePage?.clientHeight;
      return height + "px";
    }} !important;
  }
`;

export const EditButton = styled.button`
  background-color: transparent;
  border: none;
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 24px;
  }
`;

export const SignOutButton = styled.button`
  background-color: transparent;
  border: none;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media only screen and (max-width: 700px) {
    font-size: 1rem;
  }
`;

export const UserImage = styled.img``;

export const AssessorsClientList = styled.div`
  height: 400px;
  width: 60%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;

  @media only screen and (max-width: 1024px) {
    width: 40%;
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 50px;
  }
`;

export const AssessorsClientInfo = styled.div`
  width: 350px;
  height: 35px;
  background: #fbfbfb;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  cursor: pointer;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    height: 75px;
  }
`;

export const SectionHeader = styled.h2`
  margin-bottom: 40px;
  @media only screen and (max-width: 700px) {
    font-size: 1.2rem;
    margin: 25px 0 15px 0;
  }
`;
