import styled from "styled-components";

export const SectionContainer = styled.section`
  padding: 50px 30px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.header`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-child(1) {
    cursor: pointer;
  }
`;

export const SearchBar = styled.input`
  width: calc(100% - 75px);
  border: 1px solid #0c2340;
  color: #0c2340;
  padding: 12px 0 12px 24px;

  &::placeholder {
    color: #0c2340;
  }
`;
