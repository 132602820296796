import React, { useState } from "react";

import {
  SectionContainer,
  SectionTopInfo,
  SectionHeader,
  SectionsDivisor,
  MainInfo,
} from "./HomeMonthFolders.elements";
import { Chevron } from "../../assets/svgs";

const HomeMonthFolders: React.FC = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleMenuOpening = () => {
    const chevronIcon = document.getElementById("chevron");
    const mainInfo = document.getElementById("main-info");

    if (chevronIcon && mainInfo) {
      if (isMenuOpen) {
        setIsMenuOpen(false);
        chevronIcon.style.transform = "rotate(180deg)";
        mainInfo.style.display = "none";
        return;
      }

      setIsMenuOpen(true);
      mainInfo.style.display = "flex";
      chevronIcon.style.transform = "rotate(270deg)";
      return;
    }
  };

  return (
    <SectionContainer>
      <SectionTopInfo>
        <Chevron id="chevron" onClick={handleMenuOpening} />
        <SectionHeader>Pastas mensais</SectionHeader>
        <SectionsDivisor />
      </SectionTopInfo>

      <MainInfo id="main-info">{children}</MainInfo>
    </SectionContainer>
  );
};

export default HomeMonthFolders;
