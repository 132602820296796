import React, { ChangeEvent, FormEvent, useState } from "react";
import { TextField, CircularProgress, withStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ForgotPasswordPage,
  MainInfoContainer,
  FormGroup,
  FormTitle,
  FormText,
  TextContainer,
  IllustrationContainer,
  SendButton,
} from "./ForgotPassword.elements";
import { LoginBackground, ForgotPasswordIllustration } from "../../assets/svgs";
import { AxiosFunctions } from "../../services/api-integration";
import { handleEmailValidation } from "./handler/emailValidation";

const ErrorField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
      width: "100% !important",
    },
    "& .MuiFormControl": {
      width: "100% !important",
    },
  },
})(TextField);

const ForgotPassword: React.FC = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const isEmailValid = handleEmailValidation(email, setIsEmailValid);

    if (!isEmailValid) {
      setIsLoading(false);
      return;
    }

    const apiInstance = new AxiosFunctions();

    await apiInstance.post({
      pathname: "session/password-reset/start",
      body: { email },
      onSuccess: () => {
        sessionStorage.setItem("CLIENT_EMAIL", email);
        history.push("/esqueci-a-senha/reenviar-email");
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
        toast.error(
          "Aconteceu um erro inesperado, por favor recarregue sua página e tente novamente"
        );
      },
    });
  };

  return (
    <ForgotPasswordPage>
      <MainInfoContainer>
        <IllustrationContainer>
          <ForgotPasswordIllustration />
        </IllustrationContainer>

        <FormGroup onSubmit={handleSubmit}>
          <TextContainer>
            <FormTitle>Esqueceu a senha?</FormTitle>
            <FormText>
              Enviaremos um link para a criação de uma nova para seu e-mail
            </FormText>
          </TextContainer>

          {isEmailValid ? (
            <TextField
              placeholder="E-Mail"
              type="mail"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              value={email}
            />
          ) : (
            <ErrorField
              placeholder="E-Mail"
              type="mail"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              error={true}
              helperText={"É preciso ser inserido um e-mail válido"}
              value={email}
            />
          )}

          {isLoading ? (
            <CircularProgress />
          ) : (
            <SendButton type="submit">Enviar</SendButton>
          )}
        </FormGroup>
      </MainInfoContainer>

      <LoginBackground id="background" />
    </ForgotPasswordPage>
  );
};

export default ForgotPassword;
