/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import {
  ProfileSection,
  MainSection,
  ProfileBasicInfo,
  GreetingMessages,
  Greeting,
  ProfileImage,
  AssessorsClientList,
  AssessorsClientInfo,
  SectionHeader,
} from "./AssessorProfileAdminView.elements";
import { ProfileAccountInfo } from "../../components";
import { UserDefaultIcon } from "../../assets/svgs";
import { AxiosFunctions } from "../../services/api-integration";
import { AppContext } from "../../services/context";

const AssessorProfileAdminView: React.FC = () => {
  const { setCurrentPath } = useContext(AppContext);

  const params = useParams();
  const history = useHistory();

  const assessorName = Object.values(params)[0];
  const assessorId = Object.values(params)[1];

  const [user, setUser] = useState<any>({});
  const [clients, setClients] = useState<Array<any>>([]);
  const [isGettingProfileInfo, setIsGettingProfileInfo] = useState(true);
  const [isGettingAdvisoriesInfo, setIsGettingAdvisoriesInfo] = useState(true);

  const handleRedirect = (client: {
    advisoryServices: string[];
    avatar: string;
    cnpj: string;
    companyName: string;
    createdAt: string;
    email: string;
    guid: string;
    name: string;
    phone: string;
    roles: string[];
    updatedAt: string;
  }) => {
    const aux = sessionStorage.getItem("BREADCRUMB_PATH");

    if (aux) {
      const pathArray = JSON.parse(aux);

      setCurrentPath([
        ...pathArray,
        {
          name: client.companyName,
          path: `/cliente/${client.companyName}/${client.guid}`,
        },
      ]);
    }

    history.push(`/cliente/${client.companyName}/${client.guid}`);
  };

  const getProfileInfo = async () => {
    const apiIntegration = new AxiosFunctions();
    const profileInfo = await apiIntegration.get({
      pathname: `users/${assessorId}`,
    });

    if (profileInfo) {
      const { name, email, avatar, guid, phone } = profileInfo;
      const newUser = { name, email, avatar, guid, phone };

      sessionStorage.setItem(
        `ADVISOR_PROFILE_BASIC_INFO_${assessorId}_${assessorName}`,
        JSON.stringify(newUser)
      );

      setUser(newUser);
    }

    setIsGettingProfileInfo(false);
  };

  const getAdvisoriesInfo = async () => {
    const apiIntegration = new AxiosFunctions();
    const advisoriesInfo = await apiIntegration.get({
      pathname: "advisory-services",
      params: {
        includeUsers: true,
      },
    });

    const filteredAdvisories = advisoriesInfo.filter((advisory: any) => {
      const advisoryFiltered = advisory.users.filter(
        (user: string) => user === assessorId
      );

      return advisoryFiltered.length;
    });

    sessionStorage.setItem(
      `ADVISOR_ADVISORIES_INFO_${assessorId}_${assessorName}`,
      JSON.stringify(filteredAdvisories)
    );

    setClients(filteredAdvisories);
    setIsGettingAdvisoriesInfo(false);
  };

  useEffect(() => {
    const advisorProfileInfo = sessionStorage.getItem(
      `ADVISOR_PROFILE_BASIC_INFO_${assessorId}_${assessorName}`
    );
    const advisorAdvisoriesInfo = sessionStorage.getItem(
      `ADVISOR_ADVISORIES_INFO_${assessorId}_${assessorName}`
    );

    if (!advisorProfileInfo) getProfileInfo();
    if (!advisorAdvisoriesInfo) getAdvisoriesInfo();

    if (advisorAdvisoriesInfo && advisorProfileInfo) {
      setClients(JSON.parse(advisorAdvisoriesInfo));
      setUser(JSON.parse(advisorProfileInfo));

      setIsGettingAdvisoriesInfo(false);
      setIsGettingProfileInfo(false);
    }
  }, []);

  return (
    <ProfileSection id="section-container">
      <MainSection>
        {isGettingProfileInfo || isGettingAdvisoriesInfo ? (
          <div
            style={{
              width: "100%",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <ProfileBasicInfo>
              <ProfileImage
                style={{
                  padding: user.avatar && "0",
                  overflow: user.avatar && "hidden",
                }}
              >
                {user.avatar ? (
                  <img
                    src={user.avatar}
                    alt="Imagem do perfil"
                    style={{ height: "100%" }}
                  />
                ) : (
                  <UserDefaultIcon />
                )}
              </ProfileImage>

              <GreetingMessages>
                <Greeting>{assessorName as string}</Greeting>
              </GreetingMessages>
            </ProfileBasicInfo>

            <ProfileAccountInfo
              email={user.email}
              name={user.name}
              phone={user.phone ? user.phone : "(81) 9 0000-0000"}
            />
          </>
        )}
      </MainSection>

      {!isGettingAdvisoriesInfo && !isGettingProfileInfo && (
        <SectionHeader>Clientes do assessor</SectionHeader>
      )}
      <AssessorsClientList>
        {!isGettingAdvisoriesInfo &&
          !isGettingProfileInfo &&
          clients &&
          clients.map((client, index) => (
            <AssessorsClientInfo
              onClick={() => handleRedirect(client["clients"][0])}
              key={`${client["clients"][0]["name"]}+${index}`}
            >
              {client["clients"][0]["name"]}
            </AssessorsClientInfo>
          ))}
      </AssessorsClientList>
    </ProfileSection>
  );
};

export default AssessorProfileAdminView;
